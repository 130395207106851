import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authAPI } from "api";
import axios from "axios";
import { EmailAndPhone, EmailOrPhone } from "features/job-seekers-invitation/types";
import { UserTypes, RejectWithError, RejectWithMessage } from "features/types";
import { tokenStorage } from "services";
import { JobSeekerSignInWithCodeState, SignInWithCodeStep } from "./types";

export const jobSeekerSignInSendCode = createAsyncThunk<EmailAndPhone, EmailOrPhone, RejectWithError>(
  'job-seeker-sign-in-with-code/job-seeker-sign-in-send-code',
  async (data, { rejectWithValue }) => {
    try {
      return await authAPI.jobSeekerSignInSendCode(data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error as Error);
      }
    }
  }
);

export const setJobSeekerAccessToken = createAsyncThunk<any, any, RejectWithMessage>(
  'job-seeker-sign-in-with-code/set-access-token',
  async (data, { rejectWithValue }) => {
    try {
      const { accessToken, refreshToken } = data;
      tokenStorage.init(accessToken, refreshToken, UserTypes.JobSeeker);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialData = {
  step: SignInWithCodeStep.PhoneNumber,
  emailAndPhone: null,
};

const initialState: JobSeekerSignInWithCodeState = {
  status: 'idle',
  data: initialData,
  error: null,
};

const jobSeekerSignInWithCodeSlice = createSlice({
  name: 'jobSeekerSignInWithCode',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.data.step = action.payload;
    },
    resetData: (state) => {
      state.data = initialData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(jobSeekerSignInSendCode.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(jobSeekerSignInSendCode.fulfilled, (state, action: PayloadAction<EmailAndPhone>) => {
      state.status = 'fulfilled';
      state.error = null;
      state.data.step = SignInWithCodeStep.ConfirmCode;
      state.data.emailAndPhone = action.payload;
    });
    builder.addCase(jobSeekerSignInSendCode.rejected, (state, action) => {
      state.status = 'rejected';
    });

    builder.addCase(setJobSeekerAccessToken.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setJobSeekerAccessToken.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = null;
    });
    builder.addCase(setJobSeekerAccessToken.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setStep, resetData } = jobSeekerSignInWithCodeSlice.actions;
export const jobSeekerSignInWithCodeReducer = jobSeekerSignInWithCodeSlice.reducer;
