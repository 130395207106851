import { useAppSelector } from "app/hooks";
import { selectUser } from "features/auth";
import { Roles } from "features/types";

type Props = {
  children: React.ReactNode;
  roles: Roles[];
};

const ShowForRoles = ({ children, roles }: Props) => {
  const user = useAppSelector(selectUser);
  
  if (user && roles.includes(user.role)) {
    return <>{children}</>;
  }
  return <></>;
};

export default ShowForRoles;
