import { ForgotPasswordData } from 'features/auth/types';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import schema from './schema';
import React from 'react';
import { Link } from 'react-router-dom';
import SvgIcon from 'components/SvgIcon';
import { fieldInvalidClass } from 'utils/form';
import FormError from 'components/FormError';
import { ROUTES } from 'app/routes';
import { removeWhitespaces } from 'utils/string';

type Props = {
  onFinish: (data: ForgotPasswordData, actions: FormikHelpers<ForgotPasswordData>) => Promise<void>;
};

const ForgotPasswordForm = ({ onFinish }: Props) => {
  return (
    <div className="form-container pre-login-form-container">
      <h4 className="form-title">Forgot password?</h4>
      <p className="description form-wrap-text">Please enter the email address you used to create your account, and we will send you a link to reset your password</p>
      <Formik<ForgotPasswordData>
        initialValues={{
          email: ''
        }}
        onSubmit={onFinish}
        validationSchema={schema}
      >
        {({ isSubmitting, touched, errors, setFieldValue }) => (
          <Form>
            <div className="form-wrap">
              <div className="form-group">
                <label className="form-label" htmlFor="email">Your email address</label>
                <Field
                  id="email"
                  name="email"
                  className={`form-control ${fieldInvalidClass<ForgotPasswordData>(touched, errors, 'email')}`.trim()}
                  placeholder="Enter your email address"
                  onChange={(event: React.ChangeEvent) => setFieldValue('email', removeWhitespaces((event.target as HTMLInputElement).value))}
                />
                <FormError name="email" />
              </div>
            </div>
            <div className="d-grid submit-wrap submit-wrap-sticky">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                Send link
              </button>
              <Link className="btn back-btn btn-icon" to={ROUTES.SIGN_IN}> <SvgIcon name="back-gray" className="mt-0"/> Back</Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
