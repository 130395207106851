import { useAppSelector } from 'app/hooks';
import { selectIsAuthenticated, selectUser } from 'features/auth';
import { useEffect, useMemo, useState } from 'react';
import Footer from 'layout/common/Footer';
import { CompanyStatuses } from 'features/types';
import ScrollToTop from 'components/ScrollToTop';
import { AppNavbar } from 'layout/AppNavbar';
import { Header } from 'layout/common/Header';

export type Props = {
  children: React.ReactNode;
};

const UserLayout = ({ children }: Props) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUser);
  const showSideBar = useMemo(() => isAuthenticated && user && (user.activeAccountStatus === CompanyStatuses.OrganizationCompleted || user.activeAccountStatus === CompanyStatuses.EmployerCompleted), [isAuthenticated, user]);
  const showHeaderAppNavbar = useMemo(() =>
    isAuthenticated && user && user.activeAccountStatus && [CompanyStatuses.OrganizationCompleted, CompanyStatuses.OrganizationDisabled, CompanyStatuses.EmployerCompleted, CompanyStatuses.EmployerDisabled].includes(user.activeAccountStatus),
  [isAuthenticated, user]);

  const [layoutStyle, setLayoutStyle] = useState<string>('');
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const toggleSidebar = () => setSidebarOpen((sidebarOpen) => !sidebarOpen);

  const handleResize  = () => {
    if (window.innerWidth > 767) {
      setLayoutStyle('desktop');
    } else {
      setLayoutStyle('mobile');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  },[]);

  return (
    <>
      <ScrollToTop />
      {showHeaderAppNavbar && (
        <Header toggleSidebar={toggleSidebar} />
      )}
      <div className={`app app-layout-full ${layoutStyle} ${showHeaderAppNavbar ? 'app-layout-authorized' : ''} ${!showSideBar ? 'centered' : ''}`.trim()}>
        {showSideBar && (
          <AppNavbar sidebarOpen={sidebarOpen} closeSidebar={() => setSidebarOpen(false)} />
        )}
        <div className={`app-content container-fluid`}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UserLayout;
