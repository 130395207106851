const PAGE_SIZE = 20;

const initialRequestData = {
  filters: {
    tab: "All",
  },
  paging: {
    page: 1,
    pageSize: PAGE_SIZE,
  },
  sorting: {
    id: 'id',
    order: 'DESC',
  },
};

export default initialRequestData;
