import { Route } from 'react-router-dom';
import { AppRoute } from 'app/routes';
import UserPrivateRoute from './UserPrivateRoute';
import { UserTypes } from 'features/types';
import JobSeekerPrivateRoute from './JobSeekerPrivateRoute';

const RouteWrapper = (props: AppRoute) => {
  const { userType, ...routeProps } = props;

  let RenderRoute;
  switch (userType) {
    case UserTypes.User:
      RenderRoute = UserPrivateRoute;
      break;
    case UserTypes.JobSeeker:
      RenderRoute = JobSeekerPrivateRoute;
      break;
    default:
      RenderRoute = Route;
      break;
  }

  return <RenderRoute {...routeProps} />;
};

export default RouteWrapper;
