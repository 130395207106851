import { UserTypes } from 'features/types';

const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';
const ACCOUNT_TYPE = 'account-type';

const init = (accessToken: string, refreshToken: string, accountType: UserTypes): void => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setAccountType(accountType);
};

const clear = (): void => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ACCOUNT_TYPE);
};

const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

const setAccessToken = (accessToken: string): void => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

const setAccountType = (accountType: UserTypes) => localStorage.setItem(ACCOUNT_TYPE, accountType);
const getAccountType = () => localStorage.getItem(ACCOUNT_TYPE);

const tokenStorage = {
  init,
  clear,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  getAccountType,
};

export default tokenStorage;
