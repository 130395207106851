import { useAppSelector } from 'app/hooks';
import { selectIsJobSeekerAuthenticated } from 'features/job-seeker-auth';
import JobSeekerAppLayout from './JobSeekerAppLayout';
import UserLayout from './UserLayout';

export type Props = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const isJobSeekerAuthenticated = useAppSelector(selectIsJobSeekerAuthenticated);

  if (isJobSeekerAuthenticated) {
    return <JobSeekerAppLayout>{children}</JobSeekerAppLayout>
  } else {
    return <UserLayout>{children}</UserLayout>
  }
};

export default AppLayout;
