import { useField } from "formik";

type TextAreaProps = {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  optionalInfo?: boolean;
  className?: string;
};

export const TextArea = ({ label, optionalInfo = false, ...props }: TextAreaProps) => {
  const [field] = useField({ ...props });
  return (
    <>
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
        {optionalInfo && (<span className="optional">Optional</span>)}
      </label>
      <textarea
        {...field} {...props}
      />
    </>
  );
};