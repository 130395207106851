import { GetOpportunitiesTable, OpportunitiesTableResponse } from 'features/opportunities/types';
import { client, withDataRequest } from 'utils/client';
import { GetSelectList, GetSelectListResponse, Tab } from './types';
import { JobDetails } from 'features/jobs/types';
import { JobSeekerOpportunitiesTableResponse } from 'features/job-seeker-opportunities';
import { GetOpportunitiesTabsFilters, OpportunitiesFiltersData } from 'features/opportunities-filters';

const opportunitiesAPI = {
  getOrganizationOpportunitiesTabs(params: GetOpportunitiesTabsFilters) {
    return client.post<Tab[]>('/opportunities/get-organization-tabs', withDataRequest(params));
  },
  getOrganizationOpportunitiesTable(params: GetOpportunitiesTable) {
    return client.post<OpportunitiesTableResponse>('/opportunities/get-organization-table', withDataRequest(params))
  },
  getAvailableToRespondJobSeekers(params: GetSelectList, jobId: string) {
    return client.post<GetSelectListResponse>(`/opportunities/${jobId}/get-available-to-respond-job-seekers`, withDataRequest(params));
  },
  organizationRespond(params: FormData, jobId: string) {
    return client.post<void>(`/opportunities/${jobId}/organization-respond`, withDataRequest(params))
  },
  getOrganizationJob(id: string) {
    return client.get<JobDetails>(`/opportunities/${id}/organization-job-details`);
  },
  getJobSeekerJob(id: string) {
    return client.get<JobDetails>(`/opportunities/${id}/job-seeker-job-details`);
  },
  getFiltersData() {
    return client.get<OpportunitiesFiltersData>('opportunities/get-filters-data');
  },
  getJobSeekerTabs(params: GetOpportunitiesTabsFilters) {
    return client.post<Tab[]>('/opportunities/get-job-seeker-tabs', withDataRequest(params));
  },
  getJobSeekerTable(params: GetOpportunitiesTable) {
    return client.post<JobSeekerOpportunitiesTableResponse>('/opportunities/get-job-seeker-table', withDataRequest(params))
  },
  getJobSeekerTableRowById(params: GetOpportunitiesTable) {
    return client.post<JobSeekerOpportunitiesTableResponse>('/opportunities/get-job-seeker-table-row-by-id', withDataRequest(params))
  },
  jobSeekerRespond(params: FormData, jobId: string) {
    return client.post<void>(`/opportunities/${jobId}/job-seeker-respond`, withDataRequest(params))
  },
  jobSeekerApplyExternalJob(jobId: string) {
    return client.post<void>(`/opportunities/${jobId}/job-seeker-apply-external-job`)
  },
  increaseViewsCount(id: string) {
    return client.post<void>(`/opportunities/${id}/job-seeker-increase-job-views-count`);
  },
};

export default opportunitiesAPI;
