import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithError, RejectWithMessage, TableDataResponse } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { jobSeekersAPI } from 'api';
import { RootState } from 'app/root-reducer';
import axios from 'axios';
import {
  JobSeekerForm,
  JobSeekersState,
  CheckEmail,
  JobSeekerTableView,
  GetJobSeekersTableFilters,
} from './types';
import { Paging } from 'api/types';
import { RequestOptions } from 'http';

export const addJobSeeker = createAsyncThunk<void, JobSeekerForm, RejectWithError>(
  'job-seekers/add-job-seeker',
  async (data, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.add(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const addExistingJobSeeker = createAsyncThunk<void, JobSeekerForm, RejectWithError>(
  'job-seekers/add-existing-job-seeker',
  async (data, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.addExisting(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getJobSeekersTable = createAsyncThunk<TableDataResponse<JobSeekerTableView>, void, RejectWithMessage>(
  'job-seekers/get-job-seekers-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.jobSeeker.requestData;
      return await jobSeekersAPI.getJobSeekersTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const importJobSeekers = createAsyncThunk<void, RequestOptions, RejectWithMessage>(
  'job-seekers/bulk-import',
  async (data, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.importJobSeekers(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        const customError = new Error("Something went wrong");
        return rejectWithValue(getErrorMessage(customError));
      }
      return rejectWithValue(getErrorMessage(error as Error));
    }
  }
);

export const findByEmail = createAsyncThunk<JobSeekerForm, CheckEmail, RejectWithError>(
  'job-seekers/find-by-email',
  async (data, { rejectWithValue }) => {
    try {
      const response = await jobSeekersAPI.findByEmail(data);
      return !response ? data : response;
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error as Error);
      }
    }
  }
);

const addForm: JobSeekerForm = {
  id: '',
  name: '',
  email: '',
  tags: [],
};

const initialRequestData = {
  filters: {},
  paging: {
    page: 1,
    pageSize: 20,
  },
};

const initialState: JobSeekersState = {
  status: 'idle',
  requestData: initialRequestData,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  },
  addForm,
  error: null,
};

const jobSeekerSlice = createSlice({
  name: 'jobSeekers',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetJobSeekersTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobSeekersTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getJobSeekersTable.fulfilled, (state, action: PayloadAction<TableDataResponse<JobSeekerTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getJobSeekersTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(findByEmail.fulfilled, (state, action: PayloadAction<JobSeekerForm>) => {
      state.addForm = action.payload;
      state.error = null;
    });

    builder.addCase(addExistingJobSeeker.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(addExistingJobSeeker.fulfilled, (state) => {
      state.status = 'fulfilled';
      state.addForm = addForm;
      state.error = null;
    });
    builder.addCase(addExistingJobSeeker.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setPage, setFilters, resetRequestData, resetTableData } = jobSeekerSlice.actions;
export const jobSeekerReducer = jobSeekerSlice.reducer;
