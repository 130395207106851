import { DefaultState } from "features/types";

export type JobSeekersInvitationState = DefaultState & {
  invitation: Invitation;
};

export type Invitation = {
  step: number;
  email: string | null;
  token: string | null;
};

export type JobSeekerSignUpSendCode = {
  email: string;
  token?: string;
};

export type EmailOrPhone = {
  emailOrPhone: string;
};

export type EmailAndPhone = {
  email?: string;
  phone?: string;
};

export type PhoneConfirmationCode = {
  email: string;
  code: string;
  token?: string;
};

export enum InvitationSteps {
  EMAIL = 0,
  CONFIRMATION_CODE = 1,
}

export enum InvitationType {
  QR = 'QR',
  LINK = 'LINK',
}

export type ShareLinkInfo = {
  organizationLogo?: string;
  programName: string;
  programDescription?: string;
  programAgreements?: string;
};

export type ShareLinkInfoResponse = {
  info?: ShareLinkInfo;
  applyLink?: string;
};
