import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getErrorMessage } from 'utils/error';
import { programsAPI } from 'api';
import { ProgramsFiltersData, ProgramsFiltersState, GetProgramsFilters } from './types';
import { RejectWithMessage } from 'features/types';

export const getFiltersData = createAsyncThunk<ProgramsFiltersData, void, RejectWithMessage>(
  'programs-filters/get-filters-data',
  async (_, { rejectWithValue }) => {
    try {
      return await programsAPI.getEmployerFilters();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: ProgramsFiltersState = {
  status: 'idle',
  filters: {},
  selectedEnhancedFiltersCount: 0,
  filtersData: null,
  error: null,
};

const programsFilters = createSlice({
  name: 'programs-filters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<GetProgramsFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
      state.selectedEnhancedFiltersCount = Object
        .entries(state.filters)
        .filter(([key, value]) => !['networks', 'location', 'text', 'fullAddress'].includes(key))
        .filter(([key, value]) => (value as string | string[])?.length > 0).length;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.selectedEnhancedFiltersCount = initialState.selectedEnhancedFiltersCount;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getFiltersData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFiltersData.fulfilled, (state, action: PayloadAction<ProgramsFiltersData>) => {
      state.status = 'fulfilled';
      state.filtersData = action.payload;
      state.error = null;
    });
    builder.addCase(getFiltersData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setFilters, resetFilters, resetState } = programsFilters.actions;
export const programsFiltersReducer = programsFilters.reducer;
