import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { programsAPI } from 'api';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { Paging } from 'api/types';
import { ProgramState, GetMyProgramsTable, ProgramFormData, MyProgramTableView, GetMyProgramsTableFilters } from 'features/programs/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';

export const getProgramTable = createAsyncThunk<TableDataResponse<MyProgramTableView>, void, RejectWithMessage>(
  'programs/get-program-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.program.requestData;
      return await programsAPI.getProgramTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getFormData = createAsyncThunk<ProgramFormData, void, RejectWithMessage>(
  'programs/get-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await programsAPI.getFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getEditFormData = createAsyncThunk<ProgramFormData, string, RejectWithMessage>(
  'programs/get-edit-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await programsAPI.getEditFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const initialRequestData: GetMyProgramsTable = {
  filters: {},
  paging: {
    page: 1,
    pageSize: 20,
  },
};

const initialState: ProgramState = {
  status: 'idle',
  requestData: initialRequestData,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    formData: null,
  },
  error: null,
};

const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData! };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData!.paging.page = action.payload.page;
      state.requestData!.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetMyProgramsTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProgramTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getProgramTable.fulfilled, (state, action: PayloadAction<TableDataResponse<MyProgramTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getProgramTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFormData.fulfilled, (state, action: PayloadAction<ProgramFormData>) => {
      state.status = 'fulfilled';
      state.data.formData = action.payload;
      state.error = null;
    });
    builder.addCase(getFormData.rejected, (state) => {
      state.status = 'rejected';
    });

    builder.addCase(getEditFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEditFormData.fulfilled, (state, action: PayloadAction<ProgramFormData>) => {
      state.status = 'fulfilled';
      state.data.formData = action.payload;
      state.error = null;
    });
    builder.addCase(getEditFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { resetRequestData, setPage, setFilters, resetTableData } = programSlice.actions;
export const programReducer = programSlice.reducer;