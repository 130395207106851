import { GetJoinNetworksTable, JoinNetworkData, JoinNetworkTableView, NetworkJoinForm } from 'features/network-join';
import { GetSubscribeNetworksTable, SubscribeNetworkTableView } from 'features/network-subscribe';
import { TableDataResponse } from 'features/types';
import { client, withDataRequest } from 'utils/client';
import { DataResponse, GetSelectList, SelectItem } from './types';
import { DiscoverNetworksTableView, GetDiscoverNetworksTable } from 'features/discover-networks';

const networksAPI = {
  getJoinTable(params: GetJoinNetworksTable) {
    return client.post<TableDataResponse<JoinNetworkTableView>>('/networks/get-join-table', withDataRequest(params));
  },
  join(params: NetworkJoinForm, networkId: string) {
    return client.put<void>(`/networks/${networkId}/join`, withDataRequest(params));
  },
  getSubscribeTable(params: GetSubscribeNetworksTable) {
    return client.post<TableDataResponse<SubscribeNetworkTableView>>('/networks/get-subscribe-table', withDataRequest(params));
  },
  getJoinData(networkId: string) {
    return client.get<JoinNetworkData>(`/networks/${networkId}/join-data`);
  },
  getSelectList(params: GetSelectList) {
    return client.post<DataResponse<SelectItem>>(`/networks/get-select-list`, withDataRequest(params));
  },
  getDiscoverTableData(params: GetDiscoverNetworksTable) {
    return client.post<TableDataResponse<DiscoverNetworksTableView>>('/networks/get-discover-table', withDataRequest(params));
  },
};

export default networksAPI;
