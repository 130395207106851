import { ToastContent } from "react-toastify";
import { CustomToastOptions } from "./types";

const defaultOptions: CustomToastOptions = {
  class: undefined,
  body: undefined,
};

export const CustomToast = (content: ToastContent, options: CustomToastOptions = defaultOptions) => {
  return (
    <div className={"toast-data" + (options.class ? ` toast-data${options.class}` : "")}>
      <div className="toast-content">
        <div className="toast-header">
          {content}
        </div>
        {options.body && <div className="toast-body">
          {options.body}
        </div>}
      </div>
    </div>
  )
};

export default CustomToast;
