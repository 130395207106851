import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { applyValidationErrors } from 'utils/form';
import customToast from 'utils/toast';

const useFormCatcher = () => {
  const [error, setError] = useState<string | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    if (error) {
      customToast.error({ body: error });
    }
    clearError();
  }, [clearError, error]);

  const handleError = useCallback(
    (
      caughtError: AxiosError,
      form: (field: string, message: string | undefined) => void,
      propertyMap?: string | undefined,
      customGeneralErrorHandler?: (error: string) => void,
      setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
    ) => {
      if (!caughtError || !caughtError.response) {
        return;
      }

      const { error: generalError, validationErrors } = caughtError.response.data;

      if (generalError && typeof generalError === 'string') {
        customGeneralErrorHandler ? customGeneralErrorHandler(generalError) : setError(generalError);
        return;
      }

      if (validationErrors && Array.isArray(validationErrors)) {
        applyValidationErrors(validationErrors, form, propertyMap, setFieldTouched);
      }
    },
    [],
  );

  return {
    error,
    clearError,
    handleError,
  };
};

export default useFormCatcher;
