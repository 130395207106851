import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { Paging } from 'api/types';
import { GetResourcesTable, GetResourcesTableFilters, ResourceFormData, ResourceState, ResourceTableView } from './types';
import { resourcesAPI } from 'api';

export const getTable = createAsyncThunk<TableDataResponse<ResourceTableView>, void, RejectWithMessage>(
  'resources/get-table',
  async(_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.resources.requestData;
      return resourcesAPI.getTable(requestData)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getFormData = createAsyncThunk<ResourceFormData, string | undefined, RejectWithMessage>(
  'resources/get-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await resourcesAPI.getFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const initialStateRequest: GetResourcesTable = {
  paging: {
    page: 1,
    pageSize: 20,
  },
  filters: {},
};

const initialState: ResourceState = {
  status: 'idle',
  error: null,
  requestData: initialStateRequest,
  data: {
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    formData: null,
  }
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetResourcesTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialStateRequest.paging;
    },
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
    resetFormData: (state) => {
      state.data.formData = initialState.data.formData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTable.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getTable.fulfilled, (state, action: PayloadAction<TableDataResponse<ResourceTableView>>) => {
      state.status = 'fulfilled';
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
      state.error = null;
    });
    builder.addCase(getTable.rejected, (state) => {
      state.status = 'rejected';
    });

    builder.addCase(getFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFormData.fulfilled, (state, action: PayloadAction<ResourceFormData>) => {
      state.status = 'fulfilled';
      state.data.formData = action.payload;
      state.error = null;
    });
    builder.addCase(getFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setPage, setFilters, resetRequestData, resetTableData, resetFormData } = resourcesSlice.actions;

export const resourcesReducer = resourcesSlice.reducer;
