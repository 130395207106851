import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobSeekersAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { JobSeekerRespondData, JobSeekerRespondState } from './types';

export const getRespondData = createAsyncThunk<JobSeekerRespondData, string | undefined, RejectWithMessage>(
  'job-seeker-respond/get-respond-data',
  async (jobSeekerId, { rejectWithValue }) => {
    try {
      if (jobSeekerId) {
        return await jobSeekersAPI.getRespondDataByOrganization(jobSeekerId);
      } else {
        return await jobSeekersAPI.getRespondDataByJobSeeker();
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: JobSeekerRespondState = {
  data: null,
  status: 'idle',
  error: null,
};

const jobSeekerRespondSlice = createSlice({
  name: 'job-seeker-respond',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRespondData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getRespondData.fulfilled, (state, action: PayloadAction<JobSeekerRespondData>) => {
      state.data = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getRespondData.rejected, (state) => {
      state.data = null;
      state.status = 'rejected';
    });
  },
});

export const { resetState } = jobSeekerRespondSlice.actions;
export const jobSeekerRespondReducer = jobSeekerRespondSlice.reducer;
