import { client, withDataRequest } from 'utils/client';
import { GetJobsTable, EmployerJobTableView, JobDetails, JobResponseTypes } from 'features/jobs/types';
import { TagFilters } from 'features/programs/types';
import { DataResponse, SelectItem } from './types';
import { Tab, TableTabFilters } from 'api/types';
import { JobEmployerFormData, JobForm, JobLocations, JobOrganizationFormData, Languages } from 'features/job-form/types';
import { capitalize } from 'utils/string';

const mapJobData = (data: JobForm): JobForm => {
  return {
    ...data,
    title: capitalize(data.title),
    fullAddress: (data.locationOption !== JobLocations.Remote && data.locationOption !== JobLocations.Travel) ? data.fullAddress : '',
    radius: data.locationOption === JobLocations.Multiple ? data.radius : '',
    location: (data.fullAddress && data.locationOption !== JobLocations.Remote && data.locationOption !== JobLocations.Travel) ? data.location : undefined,
    languageProficiency: data.language !== Languages.NotRequired ? data.languageProficiency : '',
    applyLink: data.responseType === JobResponseTypes.ApplyLink ? data.applyLink : '',
    directResponseRecipients: data.responseType === JobResponseTypes.DirectResponse ? data.directResponseRecipients : [],
  }
};

const jobsAPI = {
  getTable(params: GetJobsTable) {
    return client.post<EmployerJobTableView>('/jobs/get-table', withDataRequest(params));
  },
  getTabs(params: TableTabFilters) {
    return client.post<Tab[]>('/jobs/tabs', withDataRequest(params));
  },
  validateDraft(data: JobForm) {
    return client.post<void>(`/jobs/validate-draft`, withDataRequest(mapJobData(data)));
  },
  validatePublish(data: JobForm) {
    return client.post<void>(`/jobs/validate-publish`, withDataRequest(mapJobData(data)));
  },
  addJob(data: JobForm, id?: string) {
    return client.post<void>(`/jobs/publish?jobId=${id}`, withDataRequest(mapJobData(data)));
  },
  saveDraft(data: JobForm, id?: string) {
    return client.post<void>(`/jobs/save-draft?jobId=${id}`, withDataRequest(mapJobData(data)));
  },
  organizationAdd(data: JobForm) {
    return client.post<void>(`/jobs/organization-add`, withDataRequest(mapJobData(data)));
  },
  organizationEdit(data: JobForm, id: string) {
    return client.put<void>(`/jobs/${id}/organization-edit`, withDataRequest(mapJobData(data)));
  },
  deleteJob(id: string) {
    return client.delete<void>(`/jobs/${id}`);
  },
  getSkills(params: TagFilters) {
    return client.post<DataResponse<SelectItem>>('/skills/get-select-list', withDataRequest(params));
  },
  getCertifications(params: TagFilters) {
    return client.post<DataResponse<SelectItem>>('/certifications/get-select-list', withDataRequest(params));
  },
  getJob(id: string) {
    return client.get<JobDetails>(`/jobs/${id}/job-details`);
  },
  getEmployerFormData(id: string | undefined) {
    if (id) {
      return client.get<JobEmployerFormData>(`/jobs/${id}/employer-form-data`);
    } else {
      return client.get<JobEmployerFormData>('/jobs/employer-form-data');
    }
  },
  getOrganizationFormData(id: string | undefined) {
    if (id) {
      return client.get<JobOrganizationFormData>(`/jobs/${id}/organization-form-data`);
    } else {
      return client.get<JobOrganizationFormData>('/jobs/organization-form-data');
    }
  },
  archiveJob(id: string) {
    return client.post<void>(`/jobs/${id}/archive`);
  },
  getOrganizationTable(params: GetJobsTable) {
    return client.post<EmployerJobTableView>('/jobs/get-organization-table', withDataRequest(params));
  },
  getOrganizationTabsData(params: TableTabFilters) {
    return client.post<Tab[]>('/jobs/organization-tabs', withDataRequest(params));
  },
  organizationArchive(id: string) {
    return client.post<void>(`/jobs/${id}/organization-archive`);
  },
  increaseViewsCount(id: string) {
    return client.post<void>(`/jobs/${id}/increase-views-count`);
  },
};

export default jobsAPI;