import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getErrorMessage } from 'utils/error';
import { programsAPI } from 'api';
import { DiscoverProgramsState, DiscoverProgramTableView } from './types';
import { Paging } from 'api/types';
import { RootState } from 'app/root-reducer';
import { RejectWithMessage, TableDataResponse } from 'features/types';

export const getDiscoverTable = createAsyncThunk<TableDataResponse<DiscoverProgramTableView>, void, RejectWithMessage>(
  'discover-programs/get-discover-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = { ...state.discoverPrograms.requestData, filters: state.programsFilters.filters };
      return await programsAPI.getDiscoverTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: DiscoverProgramsState = {
  status: 'idle',
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    }
  },
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  },
  error: null,
};

const discoverProgramsSlice = createSlice({
  name: 'discoverPrograms',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetRequestData: (state) => {
      state.requestData = initialState.requestData;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscoverTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getDiscoverTable.fulfilled, (state, action: PayloadAction<TableDataResponse<DiscoverProgramTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getDiscoverTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  }
});

export const { setPage, resetRequestData, resetState } = discoverProgramsSlice.actions;
export const discoverProgramsReducer = discoverProgramsSlice.reducer;
