import React from "react";
import { useField } from "formik";
import './style.scss';

type RadioBoxProps = {
  label: string;
  id: string;
  name: string;
  key: string;
  onChange: (event: React.ChangeEvent) => void;
  value: string;
  checked?: boolean;
  className?: string;
  onClickCallback?: (field: string, value: any, shouldValidate?: boolean) => void;
  values?: any;
  tooltip?: boolean;
};

export const RadioBox = ({ label, className = '', onClickCallback, values, tooltip = false, ...props }: RadioBoxProps) => {
  const [field] = useField({ ...props });

  const clickCallback = () => {
    if (values && onClickCallback) {
      const selected = values[props.name] === props.value;
      onClickCallback(props.name, selected ? '' : props.value);
    }
  };

  return (
    <div className={`radio-element ${className}`.trim()}>
      <label className="form-label" htmlFor={props.id}>
        <input
          type="radio"
          {...field} {...props}
          onClick={clickCallback}
        />
        <span className="label-span">{label} {tooltip && <span id={`tooltip-${props.value}`} className="tooltip-wrap">?</span>}</span>
      </label>
    </div>
  );
};
