import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authAPI, userAPI } from 'api';
import axios from 'axios';
import { RejectWithMessage, RejectWithError } from 'features/types';
import { ConfirmedEmailResponse, ConfirmedUserResponse } from 'features/register/types';
import { RegisterFormInputs } from 'pages/register/types';
import { getErrorMessage } from 'utils/error';
import { ForgotPasswordData, RestorePasswordData } from 'features/auth/types';

export const getConfirmedEmail = createAsyncThunk<ConfirmedEmailResponse, string, RejectWithMessage>(
  'register/get-confirmed-email',
  async(token, { rejectWithValue }) => {
    try {
      return await authAPI.getConfirmedEmail(token);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getUserDetails = createAsyncThunk<ConfirmedUserResponse, string, RejectWithMessage>(
  'register/get-user-details',
  async(token, { rejectWithValue }) => {
    try {
      return await userAPI.getConfirmedUser(token);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const submitUser = createAsyncThunk<void, RegisterFormInputs, RejectWithError>(
  'register/submit-user',
  async(data, { rejectWithValue }) => {
    try {
      return await authAPI.join(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const submitForgotPasswordForm = createAsyncThunk<void, ForgotPasswordData, RejectWithMessage>(
  'register/submit-forgot-password-form',
  async (data, { rejectWithValue }) => {
    try {
      return await authAPI.sendForgotPasswordForm(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(getErrorMessage(error));
      }
    }
  }
);

export const submitRestorePasswordForm = createAsyncThunk<void, RestorePasswordData, RejectWithError>(
  'register/submit-restore-password-form',
  async (data, { rejectWithValue }) => {
    try {
      return await authAPI.sendSetNewPasswordForm(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  confirmedEmail: '',
  name: '',
  status: 'idle',
  error: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfirmedEmail.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getConfirmedEmail.fulfilled, (state, action) => {
      state.confirmedEmail = action.payload.email;
      state.status = 'fulfilled';
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.confirmedEmail = action.payload.email;
      state.name = action.payload.name;
      state.status = 'fulfilled';
    });
    builder.addCase(getConfirmedEmail.rejected, (state, action) => {
      state.status = 'rejected';
    });
  },
});

export const registerReducer = registerSlice.reducer;