import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getErrorMessage } from 'utils/error';
import { programsAPI, organizationAPI } from 'api';
import { ExploreProgramsState, EmployerDiscoverProgramTableView } from './types';
import { Paging } from 'api/types';
import { RootState } from 'app/root-reducer';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { OrganizationMainFields } from 'features/organizations/types';

export const getPrograms = createAsyncThunk<TableDataResponse<EmployerDiscoverProgramTableView>, void, RejectWithMessage>(
  'explore-programs/get-programs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = { ...state.explorePrograms.requestData, filters: state.programsFilters.filters };
      return await programsAPI.getEmployerTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getCBODetails = createAsyncThunk<OrganizationMainFields, string, RejectWithMessage>(
  'explore-programs/get-cbo-details',
  async (id, { rejectWithValue }) => {
    try {
      return await organizationAPI.getCBODetails(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: ExploreProgramsState = {
  status: 'idle',
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
  },
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    selectedOrganization: null,
  },
  error: null,
};

const explorePrograms = createSlice({
  name: 'explorePrograms',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetRequestData: (state) => {
      state.requestData = initialState.requestData;
    },
    resetPaging: (state) => {
      state.requestData.paging = initialState.requestData.paging;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPrograms.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getPrograms.fulfilled, (state, action: PayloadAction<TableDataResponse<EmployerDiscoverProgramTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getPrograms.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getCBODetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCBODetails.fulfilled, (state, action: PayloadAction<OrganizationMainFields>) => {
      state.status = 'fulfilled';
      state.data.selectedOrganization = action.payload;
      state.error = null;
    });
    builder.addCase(getCBODetails.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setPage, resetRequestData, resetPaging, resetState } = explorePrograms.actions;
export const exploreProgramsReducer = explorePrograms.reducer;
