import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobSeekersAPI, userAPI } from 'api';
import { RejectWithMessage, UserTypes } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { NotificationSettingsFormData, NotificationSettingsState } from './types';

export const getNotificationSettingsFormData = createAsyncThunk<NotificationSettingsFormData, { accountType: UserTypes }, RejectWithMessage>(
  'notification-settings/get-notification-settings-form-data',
  async ({ accountType }, { rejectWithValue }) => {
    try {
      if (accountType === UserTypes.User) {
        return await userAPI.getNotificationSettingsFormData();
      } else {
        return await jobSeekersAPI.getNotificationSettingsFormData();
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: NotificationSettingsState = {
  formData: null,
  status: 'idle',
  error: null,
};

const notificationSettingsSlice = createSlice({
  name: 'notification-settings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationSettingsFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getNotificationSettingsFormData.fulfilled, (state, action: PayloadAction<NotificationSettingsFormData>) => {
      state.formData = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getNotificationSettingsFormData.rejected, (state) => {
      state.formData = null;
      state.status = 'rejected';
    });
  },
});

export const notificationSettingsReducer = notificationSettingsSlice.reducer;
