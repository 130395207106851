import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCheckAuth } from 'hooks';
import routes, { AppRoute } from 'app/routes';
import RouteWrapper from 'router/RouteWrapper';
import Loading from 'components/Loading';

import './App.scss';
import PageNotFound from 'pages/page-not-found';
import RedirectToHomePage from 'components/RedirectToHomePage';

const App = () => {
  const isLoading = useCheckAuth();
  const path = window.location.pathname;

  if (isLoading) return <Loading useLayout={false} />;

  return (
    <Suspense fallback={<Loading useLayout={false} />}>
      <Switch>
        {routes.map((route: AppRoute) => (
          <RouteWrapper key={route.path} {...route} />
        ))}
        {path === '/' ? <RedirectToHomePage /> : <Route path="*" component={PageNotFound} />}
      </Switch>
    </Suspense>
  );
};

export default App;
