import { Roles, CompanyStatuses, UserTypes, SignInSections, BackTypes } from 'features/types';
import { ComponentType, LazyExoticComponent } from 'react';
import { lazyWithRetry } from 'services';

export type AppRoute = {
  path: string;
  component: LazyExoticComponent<ComponentType<any>>;
  exact: boolean;
  allowedRoles?: Roles[];
  allowedCompanyStatuses?: (String | null)[];
  userType?: UserTypes;
  hideProgramsCheck?: boolean;
};

const ORGANIZATION_ROUTES = {
  PROGRAM_PAGE: '/programs',
  PROGRAM_NETWORKS_PAGE: '/program-networks',
  JOB_SEEKERS: '/job-seekers',
  JOB_SEEKERS_DYNAMIC: (openAddJobSeekerModal: boolean) => ({
    pathname: '/job-seekers',
    state: { openAddJobSeekerModal },
  }),
  FILE_DOWNLOAD: '/files/download/:fileId',
  JOB_SEEKER_INVITATION: '/job-seeker-invite/:token?',
  JOB_SEEKER_INVITATION_PAGE: '/job-seeker-invite',
  JOB_SEEKER_JOIN_NOW: '/job-seeker-join-now/:joinNowToken',
  OPPORTUNITIES: '/opportunities',
  OPPORTUNITIES_ADDED_INTERNALLY: '/opportunities-added-internally',
  JOB_RESPONSES_BY_ORGANIZATION: '/organization-responses',
  DISCOVER__EMPLOYERS: '/discover-employers',
  ADD_RESOURCE: '/add-resource',
  EDIT_RESOURCE: '/edit-resource/:id',
  RESOURCES: '/resources',
  RESOURCE_DETAILS: '/resources-details/:id',
  RESOURCE_DETAILS_DYNAMIC: (id: string, backLocation: string) => ({
    pathname: `/resources-details/${id}`,
    state: { backLocation },
  }),
  ADD_OPPORTUNITY: '/add-opportunity',
  EDIT_OPPORTUNITY: '/edit-opportunity/:id',
  DISCOVER_RESOURCES_BY_ORGANIZATION: '/organization-discover-resources',
};

const EMPLOYER_ROUTES = {
  JOB_POSTS: '/jobs',
  EDIT_JOB: '/edit-job/:id',
  ADD_JOB: '/add-job',
  CLONE_JOB: '/clone-job/:id',
  EXPLORE_PROGRAMS: '/explore-programs',
  EXPLORE_PROGRAM_NETWORKS: '/explore-program-networks',
  JOB_RESPONSES_BY_EMPLOYER: '/employer-responses',
};

const JOB_SEEKER_ROUTES = {
  SIGN_IN_WITH_CODE: '/job-seeker/sign-in-with-code',
  PROFILE: '/job-seeker/profile/:token?',
  PROFILE_PAGE: '/job-seeker/profile',
  FORGOT_PASSWORD: '/job-seeker/forgot-password',
  RESTORE_PASSWORD: '/job-seeker/restore-password/:restoreToken',
  OPPORTUNITIES: '/job-seeker/opportunities',
  JOB_RESPONSES: '/job-seeker/responses',
  RESOURCES: '/job-seeker/resources',
  RESOURCE_DETAILS: '/job-seeker/resources-details/:id',
};

export const ROUTES = Object.freeze({
  SIGN_IN: '/sign-in',
  SIGN_IN_DYNAMIC: (section: SignInSections) => ({
    pathname: '/sign-in',
    state: { section },
  }),
  HOME: '/home',
  JOIN: '/join',
  REGISTER: '/register/:token',
  CONFIRM_REGISTRATION: '/confirm-registration/:token',
  ACCOUNT_SETUP: '/account-setup',
  PROFILE: '/profile/:token?',
  PROFILE_PAGE: '/profile',
  FORGOT_PASSWORD: '/forgot-password',
  RESTORE_PASSWORD: '/restore-password/:restoreToken',
  DASHBOARD: '/dashboard',
  EXPIRED_LINK_PAGE: '/expired-link',
  NO_ACCOUNT_FOUND: '/no-account-found',
  NO_ACCOUNT_FOUND_DYNAMIC: (backType: BackTypes) => ({
    pathname: '/no-account-found',
    state: { backType },
  }),
  PROVIDE_THE_DETAILS: '/provide-the-details',
  AGREE_AND_JOIN: '/agree-and-join/:token',
  LOG_IN_AS: '/client-log-in-as/:token',
  DISCOVER_PROGRAMS: '/discover-programs',
  DISCOVER_RESOURCES: '/discover-resources',
  DISCOVER_RESOURCE_DETAILS: '/discover-resources-details/:id',
  HIRE_JOB_SEEKERS: '/hire-job-seekers',
  MANAGERS_SETTINGS: '/managers-settings',
  PUBLIC_JOB_BOARD_SETTINGS: '/public-job-board-settings',
  DETAILS: '/details',
  JOB_SEEKER: JOB_SEEKER_ROUTES,
  ...ORGANIZATION_ROUTES,
  ...EMPLOYER_ROUTES,
});

const routes: Array<AppRoute> = [
  {
    path: ROUTES.SIGN_IN,
    component: lazyWithRetry(() => import('pages/sign-in')),
    exact: true,
  },
  {
    path: ROUTES.JOIN,
    component: lazyWithRetry(() => import('pages/join')),
    exact: true,
  },
  {
    path: ROUTES.HOME,
    component: lazyWithRetry(() => import('pages/home')),
    exact: true,
    userType: UserTypes.User,
  },
  {
    path: ROUTES.REGISTER,
    component: lazyWithRetry(() => import('pages/register')),
    exact: true,
  },
  {
    path: ROUTES.CONFIRM_REGISTRATION,
    component: lazyWithRetry(() => import('pages/register')),
    exact: true,
  },
  {
    path: ROUTES.ACCOUNT_SETUP,
    component: lazyWithRetry(() => import('pages/account-setup')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [null, CompanyStatuses.OrganizationInCompleted, CompanyStatuses.EmployerInCompleted, CompanyStatuses.OrganizationDisabled, CompanyStatuses.EmployerDisabled]
  },
  {
    path: ROUTES.PROGRAM_PAGE,
    component: lazyWithRetry(() => import('pages/programs/Programs')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.PROGRAM_NETWORKS_PAGE,
    component: lazyWithRetry(() => import('pages/program-networks')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.JOB_SEEKERS,
    component: lazyWithRetry(() => import('pages/job-seekers/JobSeekers')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.FILE_DOWNLOAD,
    component: lazyWithRetry(() => import('pages/file/FileDownload')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted, CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.JOB_SEEKER_INVITATION,
    component: lazyWithRetry(() => import('pages/job-seekers/invitation')),
    exact: true,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.JOB_SEEKER_JOIN_NOW,
    component: lazyWithRetry(() => import('pages/job-seeker-join-now')),
    exact: true,
  },
  {
    path: ROUTES.MANAGERS_SETTINGS,
    component: lazyWithRetry(() => import('pages/managers-settings')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.ProgramManager],
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted, CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.PUBLIC_JOB_BOARD_SETTINGS,
    component: lazyWithRetry(() => import('pages/public-job-board-settings')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner],
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted, CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.DETAILS,
    component: lazyWithRetry(() => import('pages/details')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner],
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted, CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.PROFILE,
    component: lazyWithRetry(() => import('pages/profile')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted, CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: lazyWithRetry(() => import('pages/forgot-password/ForgotPassword')),
    exact: true,
  },
  {
    path: ROUTES.RESTORE_PASSWORD,
    component: lazyWithRetry(() => import('pages/restore-password/RestorePassword')),
    exact: true,
  },
  {
    path: ROUTES.JOB_POSTS,
    component: lazyWithRetry(() => import('pages/jobs')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.JOB_RESPONSES_BY_EMPLOYER,
    component: lazyWithRetry(() => import('pages/job-responses-by-employer')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.ADD_JOB,
    component: lazyWithRetry(() => import('pages/jobs/components/AddEditJob')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.EDIT_JOB,
    component: lazyWithRetry(() => import('pages/jobs/components/AddEditJob')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.CLONE_JOB,
    component: lazyWithRetry(() => import('pages/jobs/components/AddEditJob')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
  },
  {
    path: ROUTES.DASHBOARD,
    component: lazyWithRetry(() => import('pages/dashboard/Dashboard')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.EmployerDisabled, CompanyStatuses.OrganizationDisabled],
  },
  {
    path: ROUTES.OPPORTUNITIES,
    component: lazyWithRetry(() => import('pages/opportunities/Opportunities')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.OPPORTUNITIES_ADDED_INTERNALLY,
    component: lazyWithRetry(() => import('pages/opportunities-added-internally')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.JOB_RESPONSES_BY_ORGANIZATION,
    component: lazyWithRetry(() => import('pages/job-responses-by-organization')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.EXPIRED_LINK_PAGE,
    component: lazyWithRetry(() => import('pages/expired-link/ExpiredLinkPage')),
    exact: true,
  },
  {
    path: ROUTES.EXPLORE_PROGRAMS,
    component: lazyWithRetry(() => import('pages/explore-programs')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
    hideProgramsCheck: true,
  },
  {
    path: ROUTES.EXPLORE_PROGRAM_NETWORKS,
    component: lazyWithRetry(() => import('pages/program-networks-employer')),
    exact: true,
    userType: UserTypes.User,
    allowedRoles: [Roles.Owner, Roles.User],
    allowedCompanyStatuses: [CompanyStatuses.EmployerCompleted],
    hideProgramsCheck: true,
  },
  {
    path: ROUTES.DISCOVER__EMPLOYERS,
    component: lazyWithRetry(() => import('pages/discover-employers')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.JOB_SEEKER.SIGN_IN_WITH_CODE,
    component: lazyWithRetry(() => import('pages/job-seeker-sign-in-with-code')),
    exact: true,
  },
  {
    path: ROUTES.JOB_SEEKER.PROFILE,
    component: lazyWithRetry(() => import('pages/job-seeker-profile')),
    exact: true,
    userType: UserTypes.JobSeeker,
  },
  {
    path: ROUTES.JOB_SEEKER.FORGOT_PASSWORD,
    component: lazyWithRetry(() => import('pages/job-seeker-forgot-password')),
    exact: true,
  },
  {
    path: ROUTES.JOB_SEEKER.RESTORE_PASSWORD,
    component: lazyWithRetry(() => import('pages/job-seeker-restore-password')),
    exact: true,
  },
  {
    path: ROUTES.JOB_SEEKER.OPPORTUNITIES,
    component: lazyWithRetry(() => import('pages/job-seeker-opportunities')),
    exact: true,
    userType: UserTypes.JobSeeker,
  },
  {
    path: ROUTES.JOB_SEEKER.JOB_RESPONSES,
    component: lazyWithRetry(() => import('pages/job-responses-by-job-seeker')),
    exact: true,
    userType: UserTypes.JobSeeker,
  },
  {
    path: ROUTES.JOB_SEEKER.RESOURCES,
    component: lazyWithRetry(() => import('pages/job-seeker-resources')),
    exact: true,
    userType: UserTypes.JobSeeker,
  },
  {
    path: ROUTES.JOB_SEEKER.RESOURCE_DETAILS,
    component: lazyWithRetry(() => import('pages/job-seeker-resources/components/JobSeekerResourceDetails')),
    exact: true,
    userType: UserTypes.JobSeeker,
  },
  {
    path: ROUTES.AGREE_AND_JOIN,
    component: lazyWithRetry(() => import('pages/agree-and-join')),
    exact: true,
  },
  {
    path: ROUTES.NO_ACCOUNT_FOUND,
    component: lazyWithRetry(() => import('pages/no-account-found')),
    exact: true,
  },
  {
    path: ROUTES.PROVIDE_THE_DETAILS,
    component: lazyWithRetry(() => import('pages/provide-the-details')),
    exact: true,
  },
  {
    path: ROUTES.LOG_IN_AS,
    component: lazyWithRetry(() => import('pages/log-in-as')),
    exact: true,
  },
  {
    path: ROUTES.ADD_RESOURCE,
    component: lazyWithRetry(() => import('pages/resources/components/AddEditResource')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.EDIT_RESOURCE,
    component: lazyWithRetry(() => import('pages/resources/components/AddEditResource')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.RESOURCES,
    component: lazyWithRetry(() => import('pages/resources')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.DISCOVER_RESOURCES_BY_ORGANIZATION,
    component: lazyWithRetry(() => import('pages/discover-resources-by-organization')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.RESOURCE_DETAILS,
    component: lazyWithRetry(() => import('pages/resources/components/OrganizationResourceDetails')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.DISCOVER_PROGRAMS,
    component: lazyWithRetry(() => import('pages/discover-programs')),
    exact: true,
  },
  {
    path: ROUTES.DISCOVER_RESOURCES,
    component: lazyWithRetry(() => import('pages/discover-resources')),
    exact: true,
  },
  {
    path: ROUTES.DISCOVER_RESOURCE_DETAILS,
    component: lazyWithRetry(() => import('pages/discover-resources/components/DiscoverResourceDetails')),
    exact: true,
  },
  {
    path: ROUTES.HIRE_JOB_SEEKERS,
    component: lazyWithRetry(() => import('pages/hire-job-seekers')),
    exact: true,
  },
  {
    path: ROUTES.ADD_OPPORTUNITY,
    component: lazyWithRetry(() => import('pages/organization-add-edit-job')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
  {
    path: ROUTES.EDIT_OPPORTUNITY,
    component: lazyWithRetry(() => import('pages/organization-add-edit-job')),
    exact: true,
    userType: UserTypes.User,
    allowedCompanyStatuses: [CompanyStatuses.OrganizationCompleted],
  },
];

export default routes;
