import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { Paging } from 'api/types';
import { JobRespondsByJobSeekerTable, JobResponsesByJobSeekerState } from './types';
import { jobResponsesAPI } from 'api';

export const getTable = createAsyncThunk<TableDataResponse<JobRespondsByJobSeekerTable>, void, RejectWithMessage>(
  'job-responses-by-job-seeker/get-table',
  async(_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.jobResponsesByJobSeeker.requestData;
      return jobResponsesAPI.getByJobSeekerTable(requestData)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: JobResponsesByJobSeekerState = {
  status: 'idle',
  error: null,
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
  },
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  }
};

const jobResponsesByJobSeekerSlice = createSlice({
  name: 'job-responses-by-job-seeker',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getTable.fulfilled, (state, action: PayloadAction<TableDataResponse<JobRespondsByJobSeekerTable>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  }
});

export const { setPage, resetState } = jobResponsesByJobSeekerSlice.actions;

export const jobResponsesByJobSeekerReducer = jobResponsesByJobSeekerSlice.reducer;
