import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import jobsAPI from 'api/jobs';
import { RootState } from 'app/root-reducer';
import { EmployerJobTableView, JobStatuses } from 'features/jobs/types';
import { Paging, Tab, TableTabFilters } from 'api/types';
import initialRequestData from 'features/initial-request-data';
import { OrganizationJobState } from './types';

export const getTable = createAsyncThunk<EmployerJobTableView, void, RejectWithMessage>(
  'organization-jobs/get-table',
  async(_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.organizationJobs.requestData;
      return jobsAPI.getOrganizationTable(requestData)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getTabs = createAsyncThunk<Tab[], void, RejectWithMessage>(
  'organization-jobs/get-tabs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.organizationJobs.requestData.filters;
      return jobsAPI.getOrganizationTabsData(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: OrganizationJobState = {
  status: 'idle',
  error: null,
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
    filters: { tab: JobStatuses.Published },
  },
  data: {
    tabsIsLoading: false,
    tabs: [],
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  }
};

const organizationJobsSlice = createSlice({
  name: 'organization-jobs',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<TableTabFilters>>) => {
      state.requestData.filters = { ...state.requestData.filters, ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getTable.fulfilled, (state, action: PayloadAction<any>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getTabs.pending, (state) => {
      state.data.tabsIsLoading = true;
    });
    builder.addCase(getTabs.fulfilled, (state, action: PayloadAction<Tab[]>) => {
      state.data.tabsIsLoading = false;
      state.data.tabs = action.payload;
    });
    builder.addCase(getTabs.rejected, (state) => {
      state.data.tabsIsLoading = false;
    });
  }
});

export const { setFilters, setPage, resetState } = organizationJobsSlice.actions;

export const organizationJobsReducer = organizationJobsSlice.reducer;
