import { client, download, withDataRequest } from 'utils/client';
import {
  JobSeekerForm,
  CheckEmail,
  JobSeekersRequestData,
  JobSeekerTableView,
} from 'features/job-seekers/types';
import { RequestOptions } from 'http';
import { ShareLinkInfoResponse } from 'features/job-seekers-invitation/types';
import { JobSeeker } from 'features/job-seeker-auth';
import { PasswordForm } from 'features/users/types';
import { ProfileFormData } from 'features/job-seeker-profile';
import { JobSeekerRespondData } from 'features/job-seeker-respond';
import { ChangeToken, TableDataResponse } from 'features/types';
import { NotificationSettingsForm, NotificationSettingsFormData } from 'features/notification-settings';
import { JobSeekerOpportunitiesFiltersData } from 'features/job-seeker-opportunities';
import { UpdatePhoneForm } from 'components/UpdatePhone/types';
import { JobSeekerManageForm, JobSeekerManageFormData } from 'features/job-seeker-manage';
import { EmploymentStatusFormData, SetEmploymentStatusForm } from 'features/job-seeker-employment-status';
import { JobSeekerHomePageChecks, JobSeekerInitialEntryFormData } from 'features/job-seeker-initial-entry';
import { GetSelectList, GetSelectListResponse } from './types';
import { JobSeekerIsVerifiedForm, JobSeekerIsVerifiedFormData } from 'features/job-seeker-manage-for-manager';

export type JobSeekerUpdateEmailForm = {
  email: string;
  emailConfirm?: string;
};

const jobSeekersAPI = {
  add(data: JobSeekerForm) {
    return client.post<void>('/job-seekers/add', withDataRequest(data));
  },
  addExisting(data: JobSeekerForm) {
    return client.post<void>(`/job-seekers/${data.id}/add-existing`, withDataRequest(data));
  },
  getJobSeekersTable(params: JobSeekersRequestData) {
    return client.post<TableDataResponse<JobSeekerTableView>>('/job-seekers/get-table', withDataRequest(params));
  },
  getManageFormData(jobSeekerId: string) {
    return client.get<JobSeekerManageFormData>(`/job-seekers/${jobSeekerId}/manage-form-data`);
  },
  getManageForJobSeekerManagerFormData(jobSeekerId: string) {
    return client.get<JobSeekerIsVerifiedFormData>(`/job-seekers/${jobSeekerId}/manage-for-job-seeker-manager-form-data`);
  },
  manage(jobSeekerId: string, data: JobSeekerManageForm) {
    return client.put<void>(`/job-seekers/${jobSeekerId}/manage`, withDataRequest(data));
  },
  manageForJobSeekerManager(jobSeekerId: string, data: JobSeekerIsVerifiedForm) {
    return client.put<void>(`/job-seekers/${jobSeekerId}/manage-for-job-seeker-manager`, withDataRequest(data));
  },
  importJobSeekers(options: RequestOptions) {
    return client.post<void>('/job-seekers/bulk-import', options);
  },
  findByEmail(data: CheckEmail) {
    return client.post<JobSeekerForm>('/job-seekers/find-by-email', withDataRequest(data));
  },
  current() {
    return client.get<JobSeeker>('/job-seekers/current');
  },
  updatePassword(data: PasswordForm) {
    return client.put<void>('/job-seekers/update-password', withDataRequest(data));
  },
  editProfile(params: FormData) {
    return client.put<void>('/job-seekers/edit-profile', withDataRequest(params));
  },
  getProfileFormData() {
    return client.get<ProfileFormData>('/job-seekers/get-profile-form-data');
  },
  downloadResume() {
    return download('job-seekers/download-resume');
  },
  getRespondDataByOrganization(jobSeekerId: string) {
    return client.get<JobSeekerRespondData>(`/job-seekers/${jobSeekerId}/respond-data`);
  },
  getRespondDataByJobSeeker() {
    return client.get<JobSeekerRespondData>(`/job-seekers/respond-data`);
  },
  updateEmail(data: JobSeekerUpdateEmailForm) {
    return client.put<void>('/job-seekers/update-email', withDataRequest(data));
  },
  confirmEmailChange(data: ChangeToken) {
    return client.post<void>('/job-seekers/confirm-email-change', withDataRequest(data));
  },
  updatePhone(data: UpdatePhoneForm) {
    return client.put<void>('/job-seekers/update-phone', withDataRequest(data));
  },
  confirmPhoneChange(data: ChangeToken) {
    return client.post<void>('/job-seekers/confirm-phone-change', withDataRequest(data));
  },
  getShareLinkInfo(token: string) {
    return client.get<ShareLinkInfoResponse>(`auth/job-seeker/share-link-info/${token}`);
  },
  applyShareLink(token: string) {
    return client.put<void>(`/job-seekers/apply-share-link`, withDataRequest({ token }));
  },
  getNotificationSettingsFormData() {
    return client.get<NotificationSettingsFormData>(`/job-seekers/get-notification-settings-form-data`);
  },
  editNotificationSettings(data: NotificationSettingsForm) {
    return client.put<void>('/job-seekers/edit-notification-settings', withDataRequest(data));
  },
  getOpportunitiesFiltersData() {
    return client.get<JobSeekerOpportunitiesFiltersData>('/job-seekers/get-opportunities-filters-data');
  },
  homePageChecks() {
    return client.get<JobSeekerHomePageChecks>('/job-seekers/home-page-checks');
  },
  setEmploymentStatus(data: SetEmploymentStatusForm) {
    return client.put<void>('/job-seekers/set-employment-status', withDataRequest(data));
  },
  getEmploymentStatusData() {
    return client.get<EmploymentStatusFormData>('/job-seekers/employment-status-data');
  },
  getInitialEntryFormData() {
    return client.get<JobSeekerInitialEntryFormData>('/job-seekers/initial-entry-form-data');
  },
  saveInitialEntryData(data: FormData) {
    return client.put<void>('/job-seekers/save-initial-entry-data', withDataRequest(data));
  },
  getSelectList(params: GetSelectList) {
    return client.post<GetSelectListResponse>('/job-seekers/get-select-list', withDataRequest(params));
  },
  resendInviteEmail(jobSeekerId: string) {
    return client.post<void>(`/job-seekers/${jobSeekerId}/resend-invite-email`);
  },
};

export default jobSeekersAPI;
