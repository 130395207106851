import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { organizationAPI } from 'api';
import { getErrorMessage } from 'utils/error';
import { OrganizationCreateState } from './types';
import { OrganizationCreateFormData } from 'features/organizations/types';

export const getCreateFormData = createAsyncThunk<OrganizationCreateFormData, void, RejectWithMessage>(
  'organization-create/get-create-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationAPI.getCreateFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: OrganizationCreateState = {
  status: 'idle',
  error: null,
  data: {
    formData: null,
  },
};

const organizationCreateSlice = createSlice({
  name: 'organization-create',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getCreateFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCreateFormData.fulfilled, (state, action: PayloadAction<OrganizationCreateFormData>) => {
      state.status = 'fulfilled';
      state.data.formData = action.payload;
    });
    builder.addCase(getCreateFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const organizationCreateReducer = organizationCreateSlice.reducer;
