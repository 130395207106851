import { default as React, useState } from "react";
import { Tooltip as TooltipElement } from "reactstrap";
import type { Placement } from '@popperjs/core';
import './style.scss';

type Props = {
  text: JSX.Element | string;
  placement: Placement | undefined;
  target: string | HTMLElement | React.RefObject<HTMLElement>;
  className?: string;
};

export const Tooltip = ({ text, ...props}: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <TooltipElement
      {...props}
      isOpen={tooltipOpen}
      toggle={toggleTooltip}
    >
      {text}
    </TooltipElement>
  );
};

Tooltip.defaultProps = {
  placement: 'right-start',
  target: 'tooltip-wrap',
};