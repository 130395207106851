import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobSeekersAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { JobSeekerInitialEntryFormData, JobSeekerInitialEntryState } from './types';

export const getInitialEntryFormData = createAsyncThunk<JobSeekerInitialEntryFormData, void, RejectWithMessage>(
  'job-seeker-initial-entry/get-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.getInitialEntryFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: JobSeekerInitialEntryState = {
  data: null,
  status: 'idle',
  error: null,
};

const jobSeekerInitialDataSlice = createSlice({
  name: 'job-seeker-initial-entry',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getInitialEntryFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getInitialEntryFormData.fulfilled, (state, action: PayloadAction<JobSeekerInitialEntryFormData>) => {
      state.data = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getInitialEntryFormData.rejected, (state) => {
      state.data = null;
      state.status = 'rejected';
    });
  },
});

export const jobSeekerInitialDataReducer = jobSeekerInitialDataSlice.reducer;
