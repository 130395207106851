import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobSeekersAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { ProfileFormData, JobSeekerProfileState } from './types';

export const getProfileFormData = createAsyncThunk<ProfileFormData, void, RejectWithMessage>(
  'job-seeker-profile/get-profile-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.getProfileFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: JobSeekerProfileState = {
  formData: null,
  status: 'idle',
  error: null,
};

const jobSeekerProfileSlice = createSlice({
  name: 'jobSeekerAuth',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getProfileFormData.fulfilled, (state, action: PayloadAction<ProfileFormData>) => {
      state.formData = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getProfileFormData.rejected, (state) => {
      state.formData = null;
      state.status = 'rejected';
    });
  },
});

export const jobSeekerProfileReducer = jobSeekerProfileSlice.reducer;
