import SvgIcon from 'components/SvgIcon';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import './style.scss';

type Props = {
  children: JSX.Element;
  routes: string[];
  name: string;
  svgIcon: string;
};

const NavCollapse = ({ children, routes, name, svgIcon }: Props) => {
  const { pathname } = useLocation();
  
  const isActive = useCallback((route: string) => {
    const endSlashPosition = route !== "/" && route.endsWith("/") ? route.length - 1 : route.length;
    return pathname === route || (pathname.startsWith(route) && pathname.charAt(endSlashPosition) === "/");
  }, [pathname]);

  const [isOpen, setIsOpen] = useState(routes.some(route => isActive(route)));
  const toggle = () => setIsOpen(!isOpen);

  return (
    <li className="nav-collapse">
      <button className={`reset-button-style toggle-btn ${isOpen ? 'active' : ''}`} onClick={() => toggle()}>
        <span className="menu-link-title"><SvgIcon name={svgIcon} />{name}</span>
        <SvgIcon className="chevron" name="chevron" />
      </button>
      <Collapse isOpen={isOpen}>
        <div className="collapse-wrap">{children}</div>
      </Collapse>
    </li>
  );
};

export default NavCollapse;
