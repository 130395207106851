export enum AccountSetupSteps {
  PRIMARY_INFO = 0,
  DETAILS = 1,
  PREFERENCES = 2,
  SETUP_SUCCESS = 3,
};

export enum AccountTypes {
  Organization = 'Organization',
  Employer = 'Employer'
};

export type PrimaryInfo = {
  name?: string;
  fullAddress: string;
  location?: google.maps.LatLngLiteral;
};
