import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import jobBoardApi from 'api/job-board-settings';
import { JobBoardState, PublicJobBoardSettings } from './types';

const initialState: JobBoardState = {
  status: 'idle',
  error: null,
  data: null,
};

export const getJobBoardSettings = createAsyncThunk<PublicJobBoardSettings, void, RejectWithMessage>(
  'job-board-settings/get-settings',
  async (_, { rejectWithValue }) => {
    try {
      return jobBoardApi.getJobBoardSettings();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const jobBoardSettings = createSlice({
  name: 'job-board-settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobBoardSettings.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getJobBoardSettings.fulfilled, (state, action: PayloadAction<PublicJobBoardSettings>) => {
      state.status = 'fulfilled';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getJobBoardSettings.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const jobBoardReducer = jobBoardSettings.reducer;