import { DefaultState } from 'features/types';
import { SelectItem } from 'api/types';
import { JobResponseTypes, JobStatuses } from 'features/jobs/types';

export type JobFormState = DefaultState & {
  data: {
    employerFormData: JobEmployerFormData | null;
    organizationFormData: JobOrganizationFormData | null;
  }
};

export type JobFormEditBaseData = {
  code?: string;
  title?: string;
  description?: string;
  language?: Languages;
  languageProficiency?: string;
  locationOption?: JobLocations;
  location?: google.maps.LatLngLiteral | undefined;
  fullAddress?: string;
  radius?: string;
  travelLocationAreas?: string;
  jobTypes?: string[];
  payRangeMin?: string;
  payRangeMax?: string;
  payrate?: string;
  supplementalPayOptions?: string[];
  supplementalPayOther?: string;
  benefits?: string[];
  benefitsOther?: string;
  skills?: string[];
  certifications?: string[];
  niceToHaveAttributes?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  contactCalendarLink?: string;
  transportationToJobSite?: string;
  backgroundCheckGuidelines?: string;
  status?: JobStatuses;
  applyLink?: string;
  responseType?: JobResponseTypes;
  directResponseRecipients?: string[];
  publishType: JobPublishTypes;
  programs?: SelectItem[];
  experienceLevels?: string[];
};

type JobEmployerFormEditData = JobFormEditBaseData;

type JobOrganizationFormEditData = JobFormEditBaseData & {
  employerName?: string;
};

export type JobFormSelectLists = {
  languagesSelectList: SelectItem[];
  languageProficienciesSelectList: SelectItem[];
  jobLocationsSelectList: SelectItem[];
  jobTypesSelectList: SelectItem[];
  payrateOptionsSelectList: SelectItem[];
  supplementalPayOptionsSelectList: SelectItem[];
  benefitsSelectList: SelectItem[];
  transportationToJobSiteSelectList: SelectItem[];
  jobResponseTypesSelectList: SelectItem[];
  experienceLevelsSelectList: SelectItem[];
};

export type JobEmployerFormData = JobEmployerFormEditData & JobFormSelectLists & {
  limitedNumberOfPrograms: boolean;
};
export type JobOrganizationFormData = JobOrganizationFormEditData & JobFormSelectLists;

export type JobForm = {
  title: string;
  code?: string;
  description?: string;
  language?: Languages;
  languageProficiency?: string;
  locationOption?: JobLocations;
  fullAddress?: string;
  radius?: string;
  travelLocationAreas?: string;
  jobTypes?: string[];
  payRangeMin?: string;
  payRangeMax?: string;
  payrate?: string;
  supplementalPayOptions?: string[];
  supplementalPayOther?: string;
  benefits?: string[];
  benefitsOther?: string;
  skills: string[];
  certifications: string[];
  location?: google.maps.LatLngLiteral;
  niceToHaveAttributes?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactCalendarLink?: string;
  transportationToJobSite?: string;
  backgroundCheckGuidelines?: string;
  applyLink?: string;
  responseType: JobResponseTypes;
  directResponseRecipients?: string[];
  employerName?: string;
  programsIds?: string[];
  publishType: JobPublishTypes;
  experienceLevels?: string[];

  programs?: SelectItem[];
};

export enum JobPublishTypes {
  ToAll = 'ToAll',
  ToSpecific = 'ToSpecific',
}

export enum JobLocations {
  Onsite = 'Onsite',
  Remote = 'Remote',
  Multiple = 'Multiple',
  Travel = 'Travel',
}

export enum Languages {
  English = 'English',
  Spanish = 'Spanish',
  EnglishOrSpanish = 'EnglishOrSpanish',
  NotRequired = 'NotRequired',
}
