import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { opportunitiesAPI } from "api";
import { Paging, Tab } from "api/types";
import { RootState } from "app/root-reducer";
import { RejectWithMessage } from "features/types";
import { getErrorMessage } from "utils/error";
import { JobSeekerOpportunitiesState, JobSeekerOpportunitiesTableResponse } from "./types";

export const getJobSeekerOpportunitiesTabs = createAsyncThunk<Tab[], void, RejectWithMessage>(
  'job-seeker-opportunities/get-tabs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.opportunitiesFilters.currentFilters;
      return await opportunitiesAPI.getJobSeekerTabs(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getJobSeekerOpportunitiesTable = createAsyncThunk<JobSeekerOpportunitiesTableResponse, void, RejectWithMessage>(
  'job-seeker-opportunities/get-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = { ...state.jobSeekerOpportunities.requestData, filters: state.opportunitiesFilters.currentFilters };
      return await opportunitiesAPI.getJobSeekerTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: JobSeekerOpportunitiesState = {
  status: 'idle',
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
  },
  data: {
    tabsIsLoading: false,
    tabs: [],
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  },
  error: null,
};

const jobSeekerOpportunitiesSlice = createSlice({
  name: 'jobSeekerOpportunities',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobSeekerOpportunitiesTabs.pending, (state) => {
      state.data.tabsIsLoading = true;
    });
    builder.addCase(getJobSeekerOpportunitiesTabs.fulfilled, (state, action: PayloadAction<Tab[]>) => {
      state.data.tabsIsLoading = false;
      state.data.tabs = action.payload;
    });
    builder.addCase(getJobSeekerOpportunitiesTabs.rejected, (state) => {
      state.data.tabsIsLoading = false;
    });

    builder.addCase(getJobSeekerOpportunitiesTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getJobSeekerOpportunitiesTable.fulfilled, (state, action: PayloadAction<JobSeekerOpportunitiesTableResponse>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getJobSeekerOpportunitiesTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  },
});

export const { resetRequestData, setPage, resetTableData } = jobSeekerOpportunitiesSlice.actions;
export const jobSeekerOpportunitiesReducer = jobSeekerOpportunitiesSlice.reducer;
