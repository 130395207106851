import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import userAPI from 'api/user';
import { getErrorMessage } from 'utils/error';
import { UserState, UserFormData, UserDataResponse } from 'features/users/types';
import { Paging } from 'api/types';
import { RootState } from 'app/root-reducer';
import initialRequestData from 'features/initial-request-data';

export const getUserTable = createAsyncThunk<TableDataResponse<UserDataResponse>, void, RejectWithMessage>(
  'users/get-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.user.requestData;
      return await userAPI.getTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getUserFormData = createAsyncThunk<UserFormData, string | undefined, RejectWithMessage>(
  'users/get-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await userAPI.getUserFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: UserState = {
  status: 'idle',
  requestData: initialRequestData,
  error: null,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    selectedUser: null,
  }
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetSelectedUser: (state) => {
      state.data.selectedUser = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getUserTable.fulfilled, (state, action: PayloadAction<TableDataResponse<UserDataResponse>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getUserTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getUserFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getUserFormData.fulfilled, (state, action: PayloadAction<UserFormData>) => {
      state.status = 'fulfilled';
      state.data.selectedUser = action.payload;
      state.error = null;
    });
    builder.addCase(getUserFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setPage, resetSelectedUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
