import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { Paging } from 'api/types';
import { DiscoverResourceByOrganizationTableView, DiscoverResourceByOrganizationState } from './types';
import { resourcesAPI } from 'api';
import { GetResourcesTableFilters } from 'features/resources/types';

export const getTable = createAsyncThunk<TableDataResponse<DiscoverResourceByOrganizationTableView>, void, RejectWithMessage>(
  'discover-resources-by-organization/get-table',
  async(_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.discoverResourcesByOrganization.requestData;
      return resourcesAPI.getDiscoverByOrganizationTable(requestData)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: DiscoverResourceByOrganizationState = {
  status: 'idle',
  error: null,
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
    filters: {},
  },
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  }
};

const discoverResourcesByOrganizationSlice = createSlice({
  name: 'discover-resources-by-organization',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetResourcesTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialState.requestData.paging;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getTable.fulfilled, (state, action: PayloadAction<TableDataResponse<DiscoverResourceByOrganizationTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  }
});

export const { setPage, setFilters, resetState } = discoverResourcesByOrganizationSlice.actions;

export const discoverResourcesByOrganizationReducer = discoverResourcesByOrganizationSlice.reducer;
