import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { networksAPI } from 'api';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { Paging } from 'api/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { GetJoinNetworksTable, GetJoinNetworksTableFilters, JoinNetworkData, JoinNetworkTableView, NetworkJoinState } from './types';

export const getJoinTable = createAsyncThunk<TableDataResponse<JoinNetworkTableView>, void, RejectWithMessage>(
  'network-join/get-join-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.networkJoin.requestData;
      return await networksAPI.getJoinTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getJoinData = createAsyncThunk<JoinNetworkData, string, RejectWithMessage>(
  'network-join/get-join-data',
  async (networkId, { rejectWithValue }) => {
    try {
      return await networksAPI.getJoinData(networkId);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialRequestData: GetJoinNetworksTable = {
  filters: {},
  paging: {
    page: 1,
    pageSize: 20,
  },
};

const initialState: NetworkJoinState = {
  requestData: initialRequestData,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    joinDataIsLoading: false,
    joinData: null,
  },
};

const networkJoinSlice = createSlice({
  name: 'network-join',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData!.paging.page = action.payload.page;
      state.requestData!.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetJoinNetworksTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJoinTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getJoinTable.fulfilled, (state, action: PayloadAction<TableDataResponse<JoinNetworkTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getJoinTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getJoinData.pending, (state) => {
      state.data.joinDataIsLoading = true;
    });
    builder.addCase(getJoinData.fulfilled, (state, action: PayloadAction<JoinNetworkData>) => {
      state.data.joinDataIsLoading = false;
      state.data.joinData = action.payload;
    });
    builder.addCase(getJoinData.rejected, (state) => {
      state.data.joinDataIsLoading = false;
    });
  }
});

export const { resetRequestData, setPage, setFilters, resetTableData } = networkJoinSlice.actions;
export const networkJoinReducer = networkJoinSlice.reducer;
