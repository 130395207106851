import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserTypes, RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import jobsAPI from 'api/jobs';
import opportunitiesAPI from 'api/opportunities';
import { RootState } from 'app/root-reducer';
import { JobState, EmployerJobTableView, JobDetails, JobStatuses } from 'features/jobs/types';
import { Paging, Tab, TableTabFilters } from 'api/types';
import initialRequestData from 'features/initial-request-data';

export const getTable = createAsyncThunk<EmployerJobTableView, void, RejectWithMessage>(
  'jobs/get-table',
  async(_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.jobs.requestData;
      return jobsAPI.getTable(requestData)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getTabs = createAsyncThunk<Tab[], void, RejectWithMessage>(
  'jobs/get-tabs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.jobs.requestData.filters;
      return jobsAPI.getTabs(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getJobDetails = createAsyncThunk<JobDetails, { id: string; showJobFields: boolean; accountType: UserTypes }, RejectWithMessage>(
  'jobs/get-job-details',
  async ({ id, showJobFields, accountType }, { rejectWithValue }) => {
    try {
      if (showJobFields) {
        return await jobsAPI.getJob(id);
      } else {
        if (accountType === UserTypes.User) {
          return await opportunitiesAPI.getOrganizationJob(id);
        } else {
          return await opportunitiesAPI.getJobSeekerJob(id);
        }
      }
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialStateRequest = {
  ...initialRequestData,
  filters: { tab: JobStatuses.Published },
};

const initialState: JobState = {
  status: 'idle',
  error: null,
  requestData: initialStateRequest,
  data: {
    tabsIsLoading: false,
    tabs: [],
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    jobDetails: null,
  }
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<TableTabFilters>>) => {
      state.requestData.filters = { ...state.requestData.filters, ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getTable.fulfilled, (state, action: PayloadAction<any>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(getJobDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getJobDetails.fulfilled, (state, action: PayloadAction<JobDetails>) => {
      state.status = 'fulfilled';
      state.data.jobDetails = action.payload;
      state.error = null;
    });
    builder.addCase(getJobDetails.rejected, (state) => {
      state.status = 'rejected';
    });
    
    builder.addCase(getTabs.pending, (state) => {
      state.data.tabsIsLoading = true;
    });
    builder.addCase(getTabs.fulfilled, (state, action: PayloadAction<Tab[]>) => {
      state.data.tabsIsLoading = false;
      state.data.tabs = action.payload;
    });
    builder.addCase(getTabs.rejected, (state) => {
      state.data.tabsIsLoading = false;
    });
  }
});

export const { setFilters, setPage, resetRequestData, resetTableData } = jobsSlice.actions;

export const jobsReducer = jobsSlice.reducer;