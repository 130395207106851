import { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { useLocation } from 'react-router-dom';
import { rememberJobSeekerApplyJobId } from 'features/job-seeker-auth';

const useRememberJobSeekerApplyJobId = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const jobIdParam = (new URLSearchParams(location.search)).get('jobid');
    if (jobIdParam) dispatch(rememberJobSeekerApplyJobId(jobIdParam));
  }, [dispatch, location]);
}

export default useRememberJobSeekerApplyJobId;
