import { SelectItem } from "api/types";
import { DefaultState } from "features/types";

export type OpportunitiesFiltersState = DefaultState & {
  currentFilters: GetOpportunitiesTableFilters;
  selectedEnhancedFiltersCount: number;
  anyFilterSelected: boolean;
  data: {
    filters: OpportunitiesFiltersData | null;
    saveOpportunityFiltersFormData: SaveOpportunityFiltersFormData | null;
  };
};

export interface OpportunitiesFiltersData {
  payrateOptionsSelectList: SelectItem[];
  jobLocationsSelectList: SelectItem[];
  jobTypesSelectList: SelectItem[];
  languagesSelectList: SelectItem[];
  supplementalPayOptionsSelectList: SelectItem[];
  transportationToJobSiteSelectList: SelectItem[];
  benefitsSelectList: SelectItem[];
  experienceLevelsSelectList: SelectItem[];
}

export type GetOpportunitiesTableFilters = GetOpportunitiesTabsFilters & {
  tab: string;
};

export type GetOpportunitiesTabsFilters = EnhancedFiltersForm & {
  text?: string;
  location: google.maps.LatLngLiteral | null;
  fullAddress?: string;
  radius?: string | number;
};

export type EnhancedFiltersForm = {
  employerId?: string;
  employer?: SelectItem;
  payRangeMin?: string;
  payRangeMax?: string;
  payrate?: string;
  languages?: string[];
  locationOptions?: string[];
  jobTypes?: string[];
  supplementalPayOptions?: string[];
  transportationToJobSites?: string[];
  benefits?: string[];
  experienceLevels?: string[];
}

export enum OpportunityTabs {
  Open = 'Open',
  Responded = 'Responded',
}

export type SaveOpportunityFiltersBaseForm = {
  name: string;
  enableAlert: boolean;
};

export type SaveOpportunityFiltersForm = SaveOpportunityFiltersBaseForm & {
  filters?: GetOpportunitiesTabsFilters;
};

export enum FilterTypes {
  Organization = 'Organization',
  JobSeeker = 'JobSeeker',
}

export type SaveOpportunityFiltersFormData = {
  enableAlert: boolean;
  cantCreateMoreAlerts: boolean;
};
