import { client, withDataRequest } from 'utils/client';
import { PublicJobBoardSettings } from 'features/job-board-settings/types';

const jobBoardApi = {
  getJobBoardSettings() {
    return client.get<PublicJobBoardSettings>('/organizations/public-job-board-settings');
  },
  validateJobBoardForm(data: PublicJobBoardSettings) {
    return client.post<void>('/organizations/validate-public-job-board-form', withDataRequest(data));
  },
  setupJobBoard(data: PublicJobBoardSettings) {
    return client.post<void>('/organizations/setup-public-job-board', withDataRequest(data));
  },
  updateJobBoardSettings(data: PublicJobBoardSettings) {
    return client.put<void>('/organizations/update-public-job-board-settings', withDataRequest(data));
  }
};

export default jobBoardApi;