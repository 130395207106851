import { useEffect, useState } from "react";
import { LOWERCASE_REGEX, UPPERCASE_REGEX, SPECIAL_CHARS_REGEX } from "utils/consts";
import "./PasswordValidation.scss";

type Props = {
  password: string,
}

const PasswordValidation = ({ password }: Props) => {
  const [passwordNotShort, setPasswordNotShort] = useState<boolean>(false);
  const [passwordHasLowercase, setPasswordHasLowercase] = useState<boolean>(false);
  const [passwordHasUppercase, setPasswordHasUppercase] = useState<boolean>(false);
  const [passwordHasSpecialChars, setPasswordHasSpecialChars] = useState<boolean>(false);

  useEffect(() => {
    setPasswordNotShort(password.length > 7);
    setPasswordHasLowercase(LOWERCASE_REGEX.test(password));
    setPasswordHasUppercase(UPPERCASE_REGEX.test(password));
    setPasswordHasSpecialChars(SPECIAL_CHARS_REGEX.test(password));
  }, [password]);

  return (
    <div className="validation-wrap">
      <ul>
        <li className={passwordNotShort ? 'green' : ''}>At least 8 characters long</li>
        <li className={passwordHasUppercase ? 'green' : ''}>At least 1 uppercase character</li>
        <li className={passwordHasLowercase ? 'green' : ''}>At least 1 lowercase character</li>
        <li className={passwordHasSpecialChars ? 'green' : ''}>At least 1 number or special character (!, ?, @, #, etc.).</li>
      </ul>
    </div>
  )
}

export default PasswordValidation;