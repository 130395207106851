import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { networksAPI } from 'api';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { Paging } from 'api/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { DiscoverNetworksState, DiscoverNetworksTableView, GetDiscoverNetworksTable, GetDiscoverNetworksTableFilters } from './types';

export const getDiscoverTableData = createAsyncThunk<TableDataResponse<DiscoverNetworksTableView>, void, RejectWithMessage>(
  'discover-networks/get-discover-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.discoverNetworks.requestData;
      return await networksAPI.getDiscoverTableData(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialRequestData: GetDiscoverNetworksTable = {
  filters: {},
  paging: {
    page: 1,
    pageSize: 20,
  },
};

const initialState: DiscoverNetworksState = {
  requestData: initialRequestData,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  },
};

const discoverNetworksSlice = createSlice({
  name: 'discover-networks',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData!.paging.page = action.payload.page;
      state.requestData!.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetDiscoverNetworksTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetRequestData: (state) => {
      state.requestData = initialRequestData;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscoverTableData.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getDiscoverTableData.fulfilled, (state, action: PayloadAction<TableDataResponse<DiscoverNetworksTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getDiscoverTableData.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  }
});

export const { resetRequestData, setPage, setFilters, resetTableData } = discoverNetworksSlice.actions;
export const discoverNetworksReducer = discoverNetworksSlice.reducer;
