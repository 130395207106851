import { Ref } from 'react';
import SvgIcon from 'components/SvgIcon';
import { Props, components as selectComponents, StylesConfig, GroupBase, SelectInstance } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import 'simplebar/dist/simplebar.min.css';
import './select.scss';
import { DataResponse, GetSelectList, SelectItem } from 'api/types';
import SelectClearIndicator from './SelectClearIndicator';

type SelectAsyncProps<T> = Props<T> & {
  loadOptions?: LoadOptions<T, GroupBase<T>, { page: number }>;
  selectRef?: Ref<SelectInstance<T, boolean, GroupBase<T>>>;
  getSelectListFunc?: (params: GetSelectList) => Promise<DataResponse<T>>;
}

function SelectAsync<T>(props: SelectAsyncProps<T>): JSX.Element {
  const { className = '', loadOptions, components, selectRef, getSelectListFunc, ...rest } = props;

  return <>
    <AsyncPaginate
      debounceTimeout={300}
      backspaceRemovesValue={false}
      menuShouldScrollIntoView={false}
      selectRef={selectRef}
      additional={{ page: 1 }}
      loadOptions={getSelectListFunc ? async (inputValue, options, additional) => {
        const response = await getSelectListFunc({ filters: { text: inputValue }, paging: { page: additional!.page, pageSize: 10 } });
        return { options: response.rows, hasMore: response.totalCount > options.length + response.rows.length, additional: { page: additional!.page + 1 } };
      } : loadOptions!}
      components={{
        ClearIndicator: (props) => <SelectClearIndicator props={props} />,
        IndicatorSeparator: () => null,
        DropdownIndicator: (props: any) => (
          <selectComponents.DropdownIndicator {...props}>
            <SvgIcon name="chevron" />
          </selectComponents.DropdownIndicator>
        ),
        MultiValueLabel: (props: any) => (
          <selectComponents.MultiValueLabel {...props}>
            <span title={props.data.text}>
              {props.data.text}
            </span>
          </selectComponents.MultiValueLabel>
        ),
        MultiValueRemove: (props: any) => {
          return <selectComponents.MultiValueRemove {...props} />;
        }
      }}
      className={`rd__container ${className}`.trim()}
      classNamePrefix="rd"
      menuPlacement="auto"
      {...rest}
    />
  </>;
};

const styles: StylesConfig<SelectItem[], false> = {
  placeholder: (css) => ({ ...css, fontSize: '0.813rem', lineHeight: '1.25rem', color: '#90A8C0' }),
  input: (css) => ({ ...css, color: '#304C67', fontWeight: '400', fontSize: '0.813rem', lineHeight: '1.25rem' }),
  multiValue: (css) => ({ ...css, color: '#0066A4', fontWeight: '600', fontSize: '0.75rem', lineHeight: '1.25rem' }),
  control: (css) => ({ ...css, borderColor: '#D5DEE7', boxShadow: '0 2px 2px rgba(0, 102, 164, 0.08)' }),
};

SelectAsync.defaultProps = {
  styles: styles
};

export default SelectAsync;
