import { client, withDataRequest } from 'utils/client';
import { EmployerDetailsView, OrganizationCreateFormData, OrganizationPreferencesForm, OrganizationPreferencesResponse, OrganizationSettingsDetailsForm } from 'features/organizations/types';
import { OrganizationDetailsResponse, OrganizationMainFields } from 'features/organizations/types';
import { PrimaryInfo } from 'features/account-setup/types';
import { EnhancedFiltersFormData } from 'features/opportunities/types';
import { InviteEmployerForm } from 'pages/employers/components/InviteEmployerModal/types';
import { JobShareLink } from 'pages/opportunities/components/ShareModal/types';

const organizationAPI = {
  getPrimaryInfo() {
    return client.get<PrimaryInfo>('/organizations/primary-info-form-data');
  },
  setupPrimaryInfo(data: PrimaryInfo) {
    return client.post<void>('/organizations/setup-primary-info', withDataRequest(data));
  },
  getDetails() {
    return client.get<OrganizationDetailsResponse>('/organizations/details-form-data');
  },
  setupDetails(data: FormData) {
    return client.post<void>('/organizations/setup-details', withDataRequest(data));
  },
  getOrganizationDetails() {
    return client.get<OrganizationSettingsDetailsForm>('/organizations/settings-details-form-data');
  },
  setOrganizationDetails(data: FormData) {
    return client.put<void>('/organizations/edit-settings-details', withDataRequest(data));
  },
  getEmployer(organizationContactAgreementId: string) {
    return client.get<EmployerDetailsView>(`/organizations/employers/${organizationContactAgreementId}/details`);
  },
  getPreferences() {
    return client.get<OrganizationPreferencesResponse>('/organizations/preferences-form-data');
  },
  setupPreferences(data: OrganizationPreferencesForm) {
    return client.put<void>('/organizations/setup-preferences-form-data', withDataRequest(data));
  },
  getCBODetails(id: string) {
    return client.get<OrganizationMainFields>(`/organizations/${id}/details`);
  },
  getOpportunitiesFiltersData() {
    return client.get<EnhancedFiltersFormData>('/organizations/get-opportunities-filters-data');
  },
  getShareLinkToken(programId: string) {
    return client.post<{ token: string }>(`/organizations/get-share-link-token`, withDataRequest({ programId, joiningSource: 'DiscoveredProgram' }), false);
  },
  getShareLinkTokenPrivate(programId: string) {
    return client.post<{ token: string }>(`/organizations/get-share-link-token`, withDataRequest({ programId, joiningSource: 'SharedProgramLink' }));
  },
  getShareLinkTokenInvitedBy(programId: string) {
    return client.post<{ token: string }>(`/organizations/get-jobseeker-share-link-token`, withDataRequest({ programId, joiningSource: 'InvitedBy' }));
  },
  getCreateFormData() {
    return client.get<OrganizationCreateFormData>('/organizations/create-form-data');
  },
  create(data: FormData) {
    return client.post<void>('/organizations/create', withDataRequest(data));
  },
  sendInviteEmployerEmail(data: InviteEmployerForm) {
    return client.post<void>('/organizations/send-invite-employer-email', withDataRequest(data));
  },
  getJobShareLink(jobId: string) {
    return client.get<JobShareLink>(`/organizations/${jobId}/job-share-link`);
  },
};

export default organizationAPI;
