import { DefaultState, ProgramConnectionListView, TableDataResponse } from 'features/types';
import { Tab, TableTabFilters, SelectItem, Paging } from 'api/types';
import { BaseJobTableView } from 'components/JobBaseTableComponent/types';

export type JobState = DefaultState & {
  requestData: GetJobsTable;
  data: {
    tableDataIsLoading: boolean;
    tableData: TableDataResponse<EmployerJobTableView>;
    jobDetails: JobDetails | null;
    tabsIsLoading: boolean;
    tabs: Tab[];
  }
};

export type EmployerJobTableView = BaseJobTableView & {
  programs: ProgramConnectionListView[];
};

export type JobDetails = {
  code?: string;
  title: string;
  description: string;
  language: string;
  languageProficiency?: string;
  locationOption: string;
  fullAddress?: string;
  radius?: number;
  travelLocationAreas?: string;
  jobTypes: string[];
  payRangeMin: string;
  payRangeMax: string;
  payrate: string;
  supplementalPayOptions: string[];
  supplementalPayOther?: string;
  benefits: string[];
  benefitsOther?: string;
  skills: string[];
  certifications: string[];
  niceToHaveAttributes?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  contactCalendarLink?: string;
  transportationToJobSite?: string;
  backgroundCheckGuidelines?: string;
  creatorType: JobCreatorTypes;
  experienceLevels?: string[];

  employerName?: string;
  employerLogo?: string;

  programs?: ProgramConnectionListView[];
}

export type GetJobsTable = {
  paging: Paging;
  filters: TableTabFilters;
};

export enum JobStatuses {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived',
  Pending = 'Pending',
}

export const getSelectListDisplayName = (text: string, selectList: SelectItem[]) => {
  for (const item of selectList) {
    if (item.value === text) {
      return { value: text, text: item.text };
    }
  }
  return { value: text, text };
};

export enum PublishSettingsPrograms {
  PublishAll = 'PublishAll',
  PublishSpecific = 'PublishSpecific',
}

export enum JobResponseTypes {
  ApplyLink = 'ApplyLink',
  DirectResponse = 'DirectResponse',
}

export enum JobCreatorTypes {
  Employer = 'Employer',
  Organization = 'Organization',
}
