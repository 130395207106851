import axios from 'axios';

export const getErrorMessage = (error: unknown): string => {
  let errorMessage = '';
  if (axios.isAxiosError(error) && error.response) {
    errorMessage = error.response.data.error || error.response.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }
  return errorMessage;
};

export const focusToFirstError = (errors: string[]) => {
  const headerHeight = 110;
  const topOffset = 60;
  const validation: Record<string, any> = [];
  for (const error of errors) {
    const input = document.querySelector(`*[name=${error}]:not(meta)`);
    if (input) {
      validation[error] = input.getBoundingClientRect();
    }
  }

  const sorted = validation.sort((a: DOMRect, b: DOMRect) => {
    return a.x - b.x;
  });

  let firstInput = document.querySelector(`*[name=${Object.keys({ ...sorted })[0]}]:not(meta)`) as HTMLElement | null;
  if (!firstInput || firstInput.getBoundingClientRect().x === 0) {
    firstInput = document.querySelector(`*[id=${Object.keys({ ...sorted })[0]}]`) as HTMLElement | null;
  }
  if (firstInput) {
    const elementInModal = document.querySelector(`.modal *[name=${Object.keys({ ...sorted })[0]}]`) as HTMLElement | null;
    const inputPosition = firstInput.getBoundingClientRect();

    if (!elementInModal) {
      window.scrollTo(0, window.scrollY + inputPosition.y - headerHeight);
      setTimeout(() => {
        if (firstInput) firstInput.focus();
      }, 1000);
    } else {
      const modal = document.querySelector(`.modal`) as HTMLElement | null;
      modal!.scrollTop += inputPosition.y - headerHeight + topOffset;
      firstInput.focus();
    }
  }
};
