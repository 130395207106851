import { DefaultState } from 'features/types';

export enum EmploymentStatuses {
  Employed = 'Employed',
  Unemployed = 'Unemployed',
  EmployedButOpen = 'EmployedButOpen',
}

export type SetEmploymentStatusForm = {
  employmentStatus?: EmploymentStatuses;
};

export type EmploymentStatusFormData = {
  employmentStatus?: EmploymentStatuses;
};

export type JobSeekerEmploymentStatusState = DefaultState & {
  data: EmploymentStatusFormData | null;
};
