import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { employersAPI } from "api";
import { RejectWithMessage } from "features/types";
import { getErrorMessage } from "utils/error";
import { EmployerDetailsState, EmployerDetailsView } from "./types";

export const getEmployer = createAsyncThunk<EmployerDetailsView, string, RejectWithMessage>(
  'organization/get-employer',
  async (id, { rejectWithValue }) => {
    try {
      return await employersAPI.getDetails(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: EmployerDetailsState = {
  status: 'idle',
  employersDetails: null,
  error: null,
};

const employerDetailsSlice = createSlice({
  name: 'employerDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployer.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEmployer.fulfilled, (state, action: PayloadAction<EmployerDetailsView>) => {
      state.status = 'fulfilled';
      state.employersDetails = action.payload;
      state.error = null;
    });
    builder.addCase(getEmployer.rejected, (state) => {
      state.status = 'rejected';
    });
  },
});

export const employerDetailsReducer = employerDetailsSlice.reducer;
