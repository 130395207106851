import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resourcesAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { ResourceShareFormData, ResourceShareState } from './types';

export const getShareFormData = createAsyncThunk<ResourceShareFormData, string, RejectWithMessage>(
  'resource-share/get-form-data',
  async (resourceId, { rejectWithValue }) => {
    try {
      return await resourcesAPI.getShareFormData(resourceId);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: ResourceShareState = {
  data: {
    shareDataIsLoading: false,
    shareFormData: null,
  },
};

const resourceShareSlice = createSlice({
  name: 'resource-share',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getShareFormData.pending, (state) => {
      state.data.shareDataIsLoading = true;
    });
    builder.addCase(getShareFormData.fulfilled, (state, action: PayloadAction<ResourceShareFormData>) => {
      state.data.shareDataIsLoading = false;
      state.data.shareFormData = action.payload;
    });
    builder.addCase(getShareFormData.rejected, (state) => {
      state.data.shareDataIsLoading = false;
    });
  }
});

export const { resetState } = resourceShareSlice.actions;
export const resourceShareReducer = resourceShareSlice.reducer;
