import { DefaultState } from 'features/types';

export type AuthState = DefaultState & {
  isAuthenticated: boolean;
  jobSeeker: JobSeeker | null;
  from: string | null;
};

export type JobSeekerAuthState = AuthState & {
  applyJobId: string | undefined;
};

export type JobSeeker = {
  id: string;
  name: string;
  isPasswordSet: boolean;
  email?: string;
};

export type JobSeekerSignInSocialMedia = {
  credential: string;
};

export type JobSeekerSignUpSocialMedia = {
  credential: string;
  token?: string;
  joinNowToken?: string;
};

export enum SocialType {
  Google = 'Google',
  Facebook = 'Facebook',
  Apple = 'Apple',
};
