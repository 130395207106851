import { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './Header.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { deleteSession, getCurrentUser, selectUser } from 'features/auth';
import SvgIcon from 'components/SvgIcon';
import { ROUTES } from 'app/routes';
import { CompanyStatuses, Roles } from 'features/types';
import ShowForRoles from 'components/ShowForRoles';
import { userAPI } from 'api';
import { AccountTypes } from 'features/account-setup/types';
import UserRedirectToHomePage from 'components/UserRedirectToHomePage';
import customToast from 'utils/toast';

type Props = {
  toggleSidebar(): void;
};

const AppHeader = ({ toggleSidebar }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const history = useHistory();
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const toggleDropdown = (): void => setDropdownIsOpen(!dropdownIsOpen);
  const logOut = (): void => dispatch(deleteSession());
  const [accountSwitchDisabled, setAccountSwitchDisabled] = useState(false);

  const name = useMemo(() => user?.activeAccountType === AccountTypes.Organization ? user.organization?.name : user?.employer?.name, [user]);

  const setActiveAccountType = useCallback(async (accountType: AccountTypes) => {
    setAccountSwitchDisabled(true);
    try {
      await userAPI.setActiveAccountType(accountType);
      await dispatch(getCurrentUser());
      return <UserRedirectToHomePage user={user} />;
    } catch (error) {
      if (error instanceof Error) {
        customToast.error({ body: error.message });
      } else {
        customToast.error({ body: error as string });
      }
    } finally {
      setAccountSwitchDisabled(false);
    }
  }, [dispatch, user]);

  return (
    <Navbar className="navbar-header px-0" dark expand="md">
      <button className="navbar-toggler" onClick={toggleSidebar}>
        <SvgIcon name="sidebar/toggle-sidebar" />
      </button>
      <NavbarBrand to="/programs" tag={Link}>
        <SvgIcon name="logo" className="logo-with-text" title="WorkerSpring" />
        <SvgIcon name="logo-no-text" className="logo-no-text" title="WorkerSpring" />
      </NavbarBrand>
      <Nav className="ms-auto align-items-center" navbar>
        {user && <>
          <ShowForRoles roles={[Roles.Owner, Roles.User]}>
            {user.accountTypes.length > 1 &&
              <NavItem className="d-none d-sm-block">
                <button className={`btn btn-link account-switch ${user.activeAccountType === AccountTypes.Organization && 'active'}`} disabled={accountSwitchDisabled} onClick={() => setActiveAccountType(AccountTypes.Organization)}>Community view</button>
                <button className={`btn btn-link account-switch ${user.activeAccountType === AccountTypes.Employer && 'active'}`} disabled={accountSwitchDisabled} onClick={() => setActiveAccountType(AccountTypes.Employer)}>Hiring view</button>
              </NavItem>
            }
          </ShowForRoles>
          <NavItem>
            <Dropdown isOpen={dropdownIsOpen} toggle={toggleDropdown}>
              <DropdownToggle>
                <div className="user-profile">
                  <SvgIcon name="user-icon" className="user-icon" />
                  <div className="btn-user-profile">
                    <div>
                      <div className="organization-name" title={name}>
                        {name}
                      </div>
                      <div className="navbar-username" title={user.name}>
                        {user.name}
                      </div>
                    </div>
                    <SvgIcon name="chevron" className="dropdown-arrow" />
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu end={true}>
                <ShowForRoles roles={[Roles.Owner, Roles.User]}>
                  {user.accountTypes.length > 1 && <div className="d-sm-none">
                    <DropdownItem className="menu-item" onClick={() => setActiveAccountType(AccountTypes.Employer)}>Hiring view</DropdownItem>
                    <DropdownItem className="menu-item" onClick={() => setActiveAccountType(AccountTypes.Organization)}>Community view</DropdownItem>
                  </div>}
                </ShowForRoles>
                {(user.activeAccountStatus === CompanyStatuses.OrganizationCompleted || user.activeAccountStatus === CompanyStatuses.EmployerCompleted) && <>
                  <DropdownItem className="menu-item" onClick={() => history.push(ROUTES.PROFILE_PAGE)}>My profile</DropdownItem>
                </>}
                <DropdownItem className="menu-item signout-item" onClick={logOut}>
                  Sign out <SvgIcon name="signout" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavItem>
        </>}
      </Nav>
    </Navbar>
  );
};

export default AppHeader;
