import SvgIcon from 'components/SvgIcon';
import { ClearIndicatorProps, GroupBase, components } from 'react-select';
import './style.scss';

type Props<T> = {
  props: React.PropsWithChildren<ClearIndicatorProps<T, boolean, GroupBase<T>>>
};

const SelectClearIndicator = <T,>({ props }: Props<T>) => {
  return (
    <components.ClearIndicator className="clear-indicator-container" {...props}>
      <SvgIcon name="decline-icon" title="Clear value" />
    </components.ClearIndicator>
  );
};

export default SelectClearIndicator;
