import SvgIcon from 'components/SvgIcon';
import './AppNavbar.scss';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'app/routes';
import NavCollapse from 'components/NavCollapse';

type Props = {
  sidebarOpen: boolean;
  closeSidebar(): void;
};

const JobSeekerAppNavbar = ({ sidebarOpen, closeSidebar }: Props) => {
  return (
    <div className={`app-sidebar ${sidebarOpen ? 'opened' : ''}`}>
      <nav className="sidebar-nav">
        <ul>
          <li>
            <NavCollapse routes={[ROUTES.JOB_SEEKER.OPPORTUNITIES, ROUTES.JOB_SEEKER.JOB_RESPONSES]} name="Jobs" svgIcon="sidebar/document">
              <>
                <NavLink className="nav-collapse-link" to={ROUTES.JOB_SEEKER.OPPORTUNITIES} onClick={() => closeSidebar()} >
                  <span className="menu-link-title">Jobs</span>
                </NavLink>
                <NavLink className="nav-collapse-link" to={ROUTES.JOB_SEEKER.JOB_RESPONSES} onClick={() => closeSidebar()} >
                  <span className="menu-link-title">Applications</span>
                </NavLink>
              </>
            </NavCollapse>
          </li>
          <li>
            <NavLink exact to={ROUTES.JOB_SEEKER.RESOURCES} onClick={() => closeSidebar()} >
              <span className="menu-link-title"><SvgIcon name="sidebar/resources" />Resources</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default JobSeekerAppNavbar;
