import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { jobSeekersAPI } from 'api';
import { JobSeekerIsVerifiedFormData, JobSeekerManageForManagerState } from './types';

export const getManageForJobSeekerManagerFormData = createAsyncThunk<JobSeekerIsVerifiedFormData, string, RejectWithMessage>(
  'job-seeker-manage-for-manager/get-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.getManageForJobSeekerManagerFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: JobSeekerManageForManagerState = {
  status: 'idle',
  manageFormData: null,
  error: null,
};

const jobSeekerManageForManagerSlice = createSlice({
  name: 'job-seeker-manage-for-manager',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getManageForJobSeekerManagerFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getManageForJobSeekerManagerFormData.fulfilled, (state, action: PayloadAction<JobSeekerIsVerifiedFormData>) => {
      state.status = 'fulfilled';
      state.manageFormData = action.payload;
      state.error = null;
    });
    builder.addCase(getManageForJobSeekerManagerFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { resetState } = jobSeekerManageForManagerSlice.actions;
export const jobSeekerManageForManagerReducer = jobSeekerManageForManagerSlice.reducer;
