import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { jobSeekersAPI } from 'api';
import { JobSeekerManageFormData, JobSeekerManageState } from './types';

export const getManageFormData = createAsyncThunk<JobSeekerManageFormData, string, RejectWithMessage>(
  'job-seeker-manage/get-manage-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.getManageFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: JobSeekerManageState = {
  status: 'idle',
  manageFormData: null,
  error: null,
};

const jobSeekerManageSlice = createSlice({
  name: 'job-seeker-manage',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getManageFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getManageFormData.fulfilled, (state, action: PayloadAction<JobSeekerManageFormData>) => {
      state.status = 'fulfilled';
      state.manageFormData = action.payload;
      state.error = null;
    });
    builder.addCase(getManageFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { resetState } = jobSeekerManageSlice.actions;
export const jobSeekerManageReducer = jobSeekerManageSlice.reducer;
