import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithError, RejectWithMessage } from 'features/types';
import { organizationAPI } from 'api';
import axios from 'axios';
import { RootState } from 'app/root-reducer';
import {
  AccountSetupStep,
  OrganizationSetupState,
  OrganizationDetailsResponse,
  OrganizationPreferencesResponse,
  OrganizationPreferencesForm,
} from 'features/organizations/types';
import { PrimaryInfo, AccountSetupSteps } from 'features/account-setup/types';
import { getErrorMessage } from 'utils/error';

export const getOrganizationName = createAsyncThunk<PrimaryInfo, void, RejectWithMessage>(
  'organization-setup/primary-info-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationAPI.getPrimaryInfo();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const setupPrimaryInfo = createAsyncThunk<void, PrimaryInfo, RejectWithError>(
  'organization-setup/setup-primary-info',
  async (data, { rejectWithValue }) => {
    try {
      return await organizationAPI.setupPrimaryInfo(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getDetails = createAsyncThunk<OrganizationDetailsResponse, void, RejectWithMessage>(
  'organization-setup/get-details',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationAPI.getDetails();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const setupDetails = createAsyncThunk<void, FormData, RejectWithError>(
  'organization-setup/setup-details',
  async (data, { rejectWithValue }) => {
    try {
      return await organizationAPI.setupDetails(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getPreferences = createAsyncThunk<OrganizationPreferencesResponse, void, RejectWithMessage>(
  'organization-setup/get-preferences',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationAPI.getPreferences();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const setupPreferences = createAsyncThunk<void, OrganizationPreferencesForm, RejectWithError>(
  'organization-setup/setup-preferences',
  async (data, { rejectWithValue }) => {
    try {
      return await organizationAPI.setupPreferences(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState: OrganizationSetupState = {
  setupStep: null,
  status: 'idle',
  error: null,
  data: {
    primaryInfo: null,
    details: null,
    preferences: null,
  },
};

const organizationSetupSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setSetupStep: (state, action: PayloadAction<AccountSetupStep>) => {
      state.setupStep = action.payload.step;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationName.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getOrganizationName.fulfilled, (state, action) => {
      state.data.primaryInfo = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getOrganizationName.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(setupPrimaryInfo.fulfilled, (state) => {
      state.setupStep = AccountSetupSteps.DETAILS;
    });
    builder.addCase(getDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getDetails.fulfilled, (state, action) => {
      state.data.details = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getDetails.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(setupDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setupDetails.fulfilled, (state) => {
      state.setupStep = AccountSetupSteps.PREFERENCES;
      state.status = 'fulfilled';
    });
    builder.addCase(setupDetails.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(getPreferences.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getPreferences.fulfilled, (state, action) => {
      state.data.preferences = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getPreferences.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(setupPreferences.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setupPreferences.fulfilled, (state, action) => {
      state.setupStep = AccountSetupSteps.SETUP_SUCCESS;
      state.status = 'fulfilled';
    });
    builder.addCase(setupPreferences.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const organizationSetupStep = (state: RootState): number | null => state.organizationSetup.setupStep;
export const { setSetupStep } = organizationSetupSlice.actions;
export const organizationSetupReducer = organizationSetupSlice.reducer;