import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { networksAPI } from 'api';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { Paging } from 'api/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { GetSubscribeNetworksTable, GetSubscribeNetworksTableFilters, NetworkSubscribeState, SubscribeNetworkTableView } from './types';

export const getSubscribeTable = createAsyncThunk<TableDataResponse<SubscribeNetworkTableView>, void, RejectWithMessage>(
  'network-subscribe/get-subscribe-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.networkSubscribe.requestData;
      return await networksAPI.getSubscribeTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialRequestData: GetSubscribeNetworksTable = {
  filters: {},
  paging: {
    page: 1,
    pageSize: 20,
  },
};

const initialState: NetworkSubscribeState = {
  requestData: initialRequestData,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  },
};

const networkSubscribeSlice = createSlice({
  name: 'network-subscribe',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetSubscribeNetworksTableFilters>>) => {
      state.requestData.filters = { ...state.requestData.filters, ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscribeTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getSubscribeTable.fulfilled, (state, action: PayloadAction<TableDataResponse<SubscribeNetworkTableView>>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getSubscribeTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  }
});

export const { resetRequestData, setPage, setFilters, resetTableData } = networkSubscribeSlice.actions;
export const networkSubscribeReducer = networkSubscribeSlice.reducer;
