import { Redirect } from 'react-router-dom';
import { ROUTES } from 'app/routes';
import { useAppSelector } from 'app/hooks';
import { selectIsJobSeekerAuthenticated } from 'features/job-seeker-auth';
import JobSeekerRedirectToHomePage from './JobSeekerRedirectToHomePage';
import UserRedirectToHomePage from './UserRedirectToHomePage';
import { selectIsAuthenticated, selectUser } from 'features/auth';

const RedirectToHomePage = () => {
  const isUserAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUser);
  const isJobSeekerAuthenticated = useAppSelector(selectIsJobSeekerAuthenticated);

  if (isJobSeekerAuthenticated) {
    return <JobSeekerRedirectToHomePage />;
  } else if (isUserAuthenticated && user) {
    return <UserRedirectToHomePage user={user} />;
  } else {
    return <Redirect to={ROUTES.SIGN_IN} />;
  }
};

export default RedirectToHomePage;
