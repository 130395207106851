import { useEffect, useState } from 'react';
import Footer from 'layout/common/Footer';
import ScrollToTop from 'components/ScrollToTop';
import { JobSeekerAppNavbar } from 'layout/AppNavbar';
import { JobSeekerHeader } from 'layout/common/Header';

export type Props = {
  children: React.ReactNode;
};

const JobSeekerAppLayout = ({ children }: Props) => {
  const [layoutStyle, setLayoutStyle] = useState<string>('');
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const toggleSidebar = () => setSidebarOpen((sidebarOpen) => !sidebarOpen);

  const handleResize  = () => {
    if (window.innerWidth > 767) {
      setLayoutStyle('desktop');
    } else {
      setLayoutStyle('mobile');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  },[]);

  return (
    <>
      <ScrollToTop />
      <JobSeekerHeader toggleSidebar={toggleSidebar} />
      <div className={`app app-layout-full app-layout-authorized ${layoutStyle}`.trim()}>
        <JobSeekerAppNavbar sidebarOpen={sidebarOpen} closeSidebar={() => setSidebarOpen(false)} />
        <div className={`app-content container-fluid`}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default JobSeekerAppLayout;
