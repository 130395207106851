import axios, { AxiosInstance, AxiosRequestConfig, Method, ResponseType } from 'axios';
import { AppDispatch } from 'app/store';
import { deleteSession, getCurrentUser, renewAccessToken } from 'features/auth';
import tokenStorage from 'services/token-storage';
import { unwrapResult } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { UserTypes } from 'features/types';
import { getCurrentJobSeeker } from 'features/job-seeker-auth';

enum HttpStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

type RequestCallback = (accessToken: string) => void;

type AxiosCustomRequestConfig = AxiosRequestConfig & {
  authenticated?: boolean;
  _retry?: boolean;
};

type RequestOptions = {
  params?: object;
  data?: object;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: any) => void;
  signal?: AbortSignal;
};

const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const REQUEST_TIMEOUT_IN_MILLISECONDS: number = 30000;

let dispatch: AppDispatch;
let isRefreshing: boolean = false;
let subscribers: Array<RequestCallback> = [];

export const injectDispatch = (_dispatch: AppDispatch) => {
  dispatch = _dispatch;
};

const addSubscriber = (callback: RequestCallback): void => {
  subscribers.push(callback);
};

const processQueue = (accessToken: string): void => {
  subscribers.forEach((request) => request(accessToken));
  subscribers = [];
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: REQUEST_TIMEOUT_IN_MILLISECONDS,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config: AxiosCustomRequestConfig) => {
    const token: string | null = tokenStorage.getAccessToken();

    if (config.authenticated && token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = { ...error.config };

    if (error.response?.status === HttpStatusCode.FORBIDDEN) {
      if (tokenStorage.getAccountType() === UserTypes.User) {
        await dispatch(getCurrentUser());
      } else if (tokenStorage.getAccountType() === UserTypes.JobSeeker) {
        await dispatch(getCurrentJobSeeker());
      }
    }
    if (
      error.response?.status === HttpStatusCode.UNAUTHORIZED &&
      !originalRequest._retry &&
      originalRequest.authenticated
    ) {
      originalRequest._retry = true;

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken: string) => {
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
          resolve(axiosInstance.request(originalRequest));
        });
      });

      if (isRefreshing) return retryOriginalRequest;

      isRefreshing = true;

      try {
        const result = unwrapResult(await dispatch(renewAccessToken()));
        if (!result.accessToken) throw Error('Session has expired or is not valid');
        isRefreshing = false;
        processQueue(result.accessToken);
        return retryOriginalRequest;
      } catch (error) {
        isRefreshing = false;
        let errorMessage = undefined;
        if (error instanceof Error) errorMessage = error.message;
        dispatch(deleteSession(errorMessage));
      }
    }

    return Promise.reject(error);
  },
);

export async function client<ResponseData>(
  method: Method,
  endpoint: string,
  options: RequestOptions = {},
  authenticated: boolean = true,
): Promise<ResponseData> {
  try {
    const response = await axiosInstance.request<ResponseData>({
      method,
      authenticated,
      url: endpoint,
      responseType: options.responseType,
      params: options.params,
      data: options.data,
      onUploadProgress: options.onUploadProgress,
      signal: options.signal
    } as AxiosCustomRequestConfig);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function download(
  endpoint: string,
  options: RequestOptions = {},
  authenticated: boolean = true,
) {
  try {
    const response = await axiosInstance.request({
      method: 'POST',
      authenticated,
      url: endpoint,
      responseType: 'blob',
      params: options.params,
      data: options.data,
    } as AxiosCustomRequestConfig);

    const contentDisposition = response.headers['content-disposition'];
    const matchHeader = contentDisposition.match(/filename="(.*)"/);
    const fileName = matchHeader ? matchHeader[1] : "filename";
    fileDownload(response.data, fileName, response.headers['content-type']);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      
      const blobError = err.response.data as Blob;
      const error = new Error();
      error.message = JSON.parse(await blobError.text()).error;

      return Promise.reject(error);
    }
    return Promise.reject(err);
  }
}

export const withDataRequest = (data: object): RequestOptions => ({
  data,
});

export const withParamsRequest = (params: object): RequestOptions => ({
  params,
});

client.get = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
  authenticated: boolean = true,
) {
  return client<ResponseData>('GET', endpoint, options, authenticated);
};

client.post = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
  authenticated: boolean = true,
) {
  return client<ResponseData>('POST', endpoint, options, authenticated);
};

client.put = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
  authenticated: boolean = true,
) {
  return client<ResponseData>('PUT', endpoint, options, authenticated);
};

client.delete = function <ResponseData>(
  endpoint: string,
  options: RequestOptions = {},
  authenticated: boolean = true,
) {
  return client<ResponseData>('DELETE', endpoint, options, authenticated);
};
