import { SelectItem } from 'api/types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { programsAPI, organizationAPI } from 'api';
import QRCode from 'react-qr-code';
import { Tooltip } from 'components/FormElements';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectAsync from 'components/FormElements/SelectAsync';
import printJS from 'print-js';
import { useAppSelector } from 'app/hooks';
import { selectUser } from 'features/auth';
import { InvitationType } from 'features/job-seekers-invitation/types';
import { SingleValue } from 'react-select';

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  isJobSeeker?: boolean;
  isPrograms?: boolean;
};

const InvitationModal = ({ isOpen, toggleModal, isJobSeeker, isPrograms }: Props) => {
  const [programName, setProgramName] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>(InvitationType.QR);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);
  const user = useAppSelector(selectUser);
  const [shareLinkToken, setShareLinkToken] = useState<string>('');

  // Fixed link to open invitation correctly
  const shareLink = useMemo(() => `${process.env.REACT_APP_URL}agree-and-join/${shareLinkToken}`, [shareLinkToken]);

  const copySharedLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigator.clipboard.writeText(shareLink);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 5000);
  };

  const selectTab = (event: React.MouseEvent<HTMLButtonElement>, tab: string) => {
    event.preventDefault();
    setActiveTab(tab);
  };

  const print = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    printJS({
      printable: 'printable-area',
      type: 'html',
      style: '.centred {text-align: center;}',
      font_size: '50px',
      header: null,
    });
  };

  const getShareLinkToken = useCallback(async (programId: string) => {
    const response = !isJobSeeker
      ? await organizationAPI.getShareLinkTokenPrivate(programId)
      : await organizationAPI.getShareLinkTokenInvitedBy(programId);
    setShareLinkToken(response.token);
  }, [isJobSeeker]);

  useEffect(() => {
    if (!isOpen) {
      setActiveTab(InvitationType.QR);
      setShareLinkToken('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="job-seeker-invitation-modal"
      onClosed={() => {
        setLinkCopied(false);
      }}
    >
      <ModalHeader toggle={toggleModal}>
        Invite job seekers
      </ModalHeader>
      <ModalBody>
        <form>
          {!isJobSeeker
            ? <p className="text-start description">A specific program must be selected for the assignment</p>
            : <p className="text-start description">Kindly choose a program within the community to which you would like to invite another job seeker</p>
          }
          <div className="form-group">
            <label className="form-label" htmlFor="programId">
              <span>
                Program
                <span id="tooltip-wrap" className="tooltip-wrap">?</span>
              </span>
              <Tooltip
                text="Invitation link is unique for each specific program. When is selected - member will automatically become part of it once joined."
                placement="right"
              />
            </label>
            <SelectAsync<SelectItem>
              id="programId"
              name="programId"
              className="rd__scrollable"
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.value}
              onChange={value => {
                getShareLinkToken((value as SingleValue<SelectItem>)?.value ?? '');
                setProgramName((value as SingleValue<SelectItem>)?.text ?? '');
              }}
              placeholder="Select program"
              getSelectListFunc={!isJobSeeker ? programsAPI.getSelectList : programsAPI.getJobSeekerSelectList}
            />
          </div>
          {shareLinkToken !== '' && (
            <>
              {isPrograms
                ? <p className="text-start description">Below is your program specific organization's QR code and invitation link. Print or copy to share with those who you would like to become your members</p>
                : (
                  !isJobSeeker
                    ? <p className="text-start description">Below is your organization's QR code and invitation link. Print or copy to share with those who you would like to become your members</p>
                    : <p className="text-start description">Invite others to join using your personal QR code or share your sign up link via email or text. You may only invite existing community members</p>
                )
              }
              <div className="form-group full-width tabWrap">
                <div className="tabs row">
                  <div className="col">
                    <button
                      className={`tab ${activeTab === InvitationType.QR ? 'active' : ''}`}
                      onClick={(event) => {
                        selectTab(event, InvitationType.QR);
                      }}>
                      QR code
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className={`tab ${activeTab === InvitationType.LINK ? 'active' : ''}`}
                      onClick={(event) => {
                        selectTab(event, InvitationType.LINK);
                      }}>
                      Invitation link
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group qr-code-wrap" hidden={activeTab !== InvitationType.QR}>
                <label className="form-label" htmlFor="qr">QR code</label>
                <div className="qr-code box-shadow round-border text-center">
                  <QRCode value={shareLink} size={80} />
                </div>
                <div className="d-grid">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={print}
                  >
                    Print
                  </button>
                </div>
              </div>
              <div className="form-group" hidden={activeTab !== InvitationType.LINK}>
                <label className="form-label" htmlFor="qr">Invitation link</label>
                <div className="share-link">
                  <input className="form-control" type="text" value={shareLink} readOnly />
                  <button className="btn btn-primary" onClick={copySharedLink}>
                    {linkCopied ? 'Copied' : 'Copy'}
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
        <div hidden>
          <div id="printable-area" className="centred">
            {!isJobSeeker ? <h4>{user?.organization?.name}</h4> : null}
            <QRCode value={shareLink} size={400} />
            <p>{programName}</p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
};

export default InvitationModal;