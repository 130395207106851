import { AuthCredentials, ForgotPasswordData, GetAccessTokenResponse, GetUserAccessTokenResponse, JobSeekerSingWithCodeResponse, RefreshTokenResponse, RestorePasswordData } from 'features/auth/types';
import { client, withDataRequest } from 'utils/client';
import { RegisterFormInputs } from 'pages/register/types';
import { ConfirmedEmailResponse } from 'features/register/types';
import { SignUp } from 'pages/join/types';
import { EmailAndPhone, EmailOrPhone, JobSeekerSignUpSendCode, PhoneConfirmationCode } from 'features/job-seekers-invitation/types';
import { GetJobSeekerAccessTokenByPhoneCode } from 'features/job-seeker-sign-in-with-code';
import { JobSeekerCompleteSignUpForm } from 'features/provide-the-details';
import { JobSeekerSignInSocialMedia, JobSeekerSignUpSocialMedia } from 'features/job-seeker-auth';
import { ExchangeClientLogInTokenForUserTokens } from 'pages/log-in-as/types';
import { JoinNowValidationResponse } from 'pages/job-seeker-join-now/types';

const authAPI = {
  getAccessToken(user: AuthCredentials) {
    return client.post<GetUserAccessTokenResponse>('auth/get-access-token', withDataRequest(user), false );
  },

  renewAccessToken(refreshToken: string) {
    return client.post<RefreshTokenResponse>('auth/renew-access-token', withDataRequest({ refreshToken }), false );
  },
  join(data: RegisterFormInputs) {
    return client.post<void>('/auth/join', withDataRequest(data), false);
  },
  confirmEmail(data: SignUp) {
    return client.post<void>('/auth/confirm-email', withDataRequest(data), false);
  },
  getConfirmedEmail(token: string) {
    return client.get<ConfirmedEmailResponse>(`/auth/get-confirmed-email/${token}`, {}, false)
  },
  sendForgotPasswordForm(data: ForgotPasswordData) {
    return client.post<void>('/auth/password-forgot', withDataRequest(data), false);
  },
  sendSetNewPasswordForm(data: RestorePasswordData) {
    return client.post<void>('/auth/password-restore', withDataRequest(data), false);
  },
  confirmRegistration(data: SignUp) {
    return client.post<GetAccessTokenResponse>('/auth/confirm-registration', withDataRequest(data), false);
  },
  jobSeekerSignInSendCode(data: EmailOrPhone) {
    return client.post<EmailAndPhone>('/auth/job-seeker/sign-in-send-code', withDataRequest(data), false);
  },
  jobSeekerSignInWithCode(data: GetJobSeekerAccessTokenByPhoneCode) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-in-with-code', withDataRequest(data), false);
  },
  getJobSeekerAccessToken(data: AuthCredentials) {
    return client.post<JobSeekerSingWithCodeResponse>('auth/job-seeker/get-access-token', withDataRequest(data), false);
  },
  jobSeekerForgotPassword(data: ForgotPasswordData) {
    return client.post<void>('/auth/job-seeker/forgot-password', withDataRequest(data), false);
  },
  jobSeekerRestorePassword(data: RestorePasswordData) {
    return client.post<void>('/auth/job-seeker/restore-password', withDataRequest(data), false);
  },
  jobSeekerCompleteSignUp(data: JobSeekerCompleteSignUpForm) {
    return client.post<GetAccessTokenResponse>('/auth/job-seeker/complete-sign-up', withDataRequest(data));
  },
  jobSeekerSignUpSendCode(data: JobSeekerSignUpSendCode) {
    return client.post<void>('/auth/job-seeker/sign-up-send-code', withDataRequest(data));
  },
  jobSeekerSignUpWithCode(data: PhoneConfirmationCode) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-up-with-code', withDataRequest(data));
  },
  jobSeekerSignInGoogle(data: JobSeekerSignInSocialMedia) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-in-google', withDataRequest(data), false);
  },
  jobSeekerSignUpGoogle(data: JobSeekerSignUpSocialMedia) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-up-google', withDataRequest(data), false);
  },
  jobSeekerSignInFacebook(data: JobSeekerSignInSocialMedia) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-in-facebook', withDataRequest(data), false);
  },
  jobSeekerSignUpFacebook(data: JobSeekerSignUpSocialMedia) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-up-facebook', withDataRequest(data), false);
  },
  jobSeekerSignInApple(data: JobSeekerSignInSocialMedia) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-in-apple', withDataRequest(data), false);
  },
  jobSeekerSignUpApple(data: JobSeekerSignUpSocialMedia) {
    return client.post<JobSeekerSingWithCodeResponse>('/auth/job-seeker/sign-up-apple', withDataRequest(data), false);
  },
  exchangeClientLogInTokenForUserTokens(token: string) {
    return client.post<ExchangeClientLogInTokenForUserTokens>('/auth/exchange-client-log-in-token-for-user-tokens', withDataRequest({ token }), false);
  },
  validateJoinNowToken(joinNowToken: string) {
    return client.get<JoinNowValidationResponse>(`auth/job-seeker/validate-join-now-token/${joinNowToken}`);
  },
};

export default authAPI;
