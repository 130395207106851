import { client, withDataRequest } from 'utils/client';
import { EmployerDetailsResponse, EmployerFocusAreaFormData, EmployerFocusAreaForm, EmployerSettingsDetailsFormResponse, EmployerCreateFormData } from 'features/employers/types';
import { PrimaryInfo } from 'features/account-setup/types';
import { GetSelectList, GetSelectListResponse } from './types';
import { EmployerDetailsView } from 'features/organizations/types';
import { DiscoverEmployerFiltersData, DiscoverEmployerTableViewResponse, GetDiscoverEmployersTable } from 'features/discover-employers';

const employersAPI = {
  getPrimaryInfo() {
    return client.get<PrimaryInfo>('/employers/primary-info-form-data');
  },
  setupPrimaryInfo(data: PrimaryInfo) {
    return client.post<void>('/employers/setup-primary-info', withDataRequest(data));
  },
  getDetailsFormData() {
    return client.get<EmployerDetailsResponse>('/employers/details-form-data');
  },
  setupDetails(data: FormData) {
    return client.post<void>('/employers/setup-details', withDataRequest(data));
  },
  getFocusAreas() {
    return client.get<EmployerFocusAreaFormData>('/employers/focus-areas-form-data');
  },
  setupFocusAreas(data: EmployerFocusAreaForm) {
    return client.post<void>('/employers/setup-focus-areas', withDataRequest(data));
  },
  getEmployerDetails() {
    return client.get<EmployerSettingsDetailsFormResponse>('/employers/settings-details-form-data');
  },
  setEmployerDetails(data: FormData) {
    return client.put<void>('/employers/edit-settings-details', withDataRequest(data));
  },
  getDetails(employerId: string) {
    return client.get<EmployerDetailsView>(`/employers/${employerId}/details`);
  },
  getSelectList(params: GetSelectList) {
    return client.post<GetSelectListResponse>('/employers/get-select-list', withDataRequest(params));
  },
  getSelectListForOrganization(params: GetSelectList) {
    return client.post<GetSelectListResponse>('/employers/get-select-list-for-organization', withDataRequest(params));
  },
  getCreateFormData() {
    return client.get<EmployerCreateFormData>('/employers/create-form-data');
  },
  create(data: FormData) {
    return client.post<void>('/employers/create', withDataRequest(data));
  },
  getTable(params: GetDiscoverEmployersTable) {
    return client.post<DiscoverEmployerTableViewResponse>('/employers/get-table', withDataRequest(params));
  },
  getTableFilters() {
    return client.get<DiscoverEmployerFiltersData>('/employers/table-filters');
  },
};

export default employersAPI;