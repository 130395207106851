import { DefaultState, TableDataResponse } from 'features/types';
import { GetResourcesTable } from 'features/resources/types';
import { ResourceBaseTableView } from 'components/ResourceBaseTableComponent/types';

export type DiscoverResourceByOrganizationState = DefaultState & {
  requestData: GetResourcesTable;
  data: {
    tableDataIsLoading: boolean;
    tableData: TableDataResponse<DiscoverResourceByOrganizationTableView>;
  }
};

export enum ResourceSharedStatuses {
  Shared = 'Shared',
  Unshared = 'Unshared',
}

export type DiscoverResourceByOrganizationTableView = ResourceBaseTableView & {
  shareStatus?: ResourceSharedStatuses;
  shareUpdatedAt?: Date;
  shareEnabled: boolean;
};
