import { client, withDataRequest } from 'utils/client';
import { GetJobRespondsByOrganizationTable, JobRespondsByOrganizationTable } from 'features/job-responses-by-organization';
import { TableDataResponse } from 'features/types';
import { GetJobRespondsByEmployerTable, JobRespondsByEmployerTable } from 'features/job-responses-by-employer';
import { GetJobRespondsByJobSeekerTable, JobRespondsByJobSeekerTable } from 'features/job-responses-by-job-seeker';

const jobResponsesAPI = {
  getByOrganizationTable(params: GetJobRespondsByOrganizationTable) {
    return client.post<TableDataResponse<JobRespondsByOrganizationTable>>('/job-responses/get-by-organization-table', withDataRequest(params));
  },
  getByEmployerTable(params: GetJobRespondsByEmployerTable) {
    return client.post<TableDataResponse<JobRespondsByEmployerTable>>('/job-responses/get-by-employer-table', withDataRequest(params));
  },
  getByJobSeekerTable(params: GetJobRespondsByJobSeekerTable) {
    return client.post<TableDataResponse<JobRespondsByJobSeekerTable>>('/job-responses/get-by-job-seeker-table', withDataRequest(params));
  },
};

export default jobResponsesAPI;
