import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { ResourceDetails, ResourceDetailsState } from './types';
import { resourcesAPI } from 'api';

export const getDetails = createAsyncThunk<ResourceDetails, { id: string, discover: boolean }, RejectWithMessage>(
  'resource-details/get',
  async (params, { rejectWithValue }) => {
    try {
      return await resourcesAPI.getDetails(params.id, params.discover);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: ResourceDetailsState = {
  status: 'idle',
  error: null,
  data: {
    details: null,
  }
};

const resourceDetailsSlice = createSlice({
  name: 'resource-details',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getDetails.fulfilled, (state, action: PayloadAction<ResourceDetails>) => {
      state.status = 'fulfilled';
      state.data.details = action.payload;
      state.error = null;
    });
    builder.addCase(getDetails.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const resourceDetailsReducer = resourceDetailsSlice.reducer;
