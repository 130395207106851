import { EmailAndPhone } from 'features/job-seekers-invitation/types';
import { DefaultState } from 'features/types';

export type GetJobSeekerAccessTokenByPhoneCode = {
  emailOrPhone: string;
  code: string;
}

export enum SignInWithCodeStep {
  PhoneNumber = 'PhoneNumber',
  ConfirmCode = 'ConfirmCode',
}

export type JobSeekerSignInWithCodeState = DefaultState & {
  data: {
    step: SignInWithCodeStep;
    emailAndPhone: EmailAndPhone | null;
  };
};
