import { User } from 'features/auth/types';
import { client, withDataRequest } from 'utils/client';
import { GetSelectList, GetSelectListResponse, ManageableData, RequestParams, URLParams } from 'api/types';
import { UserForm, ProfileEditForm, PasswordForm, UserFormData, UserDataResponse } from 'features/users/types';
import { ConfirmedUserResponse } from 'features/register/types';
import { AccountTypes } from 'features/account-setup/types';
import { NotificationSettingsForm, NotificationSettingsFormData } from 'features/notification-settings';
import { UpdatePhoneForm } from 'components/UpdatePhone/types';
import { ChangeToken, TableDataResponse } from 'features/types';
import { ProfileFormData } from 'features/user-profile';

export type GetJobSeekerManagersSelectListFilters = {
  text?: string | null;
  programId: string;
}

const userAPI = {
  me() {
    return client.get<User>('users/current');
  },
  getTable(params: RequestParams) {
    return client.post<TableDataResponse<UserDataResponse>>('/users/get-table', withDataRequest(params));
  },
  add(data: UserForm) {
    return client.post<void>('/users/add', withDataRequest(data));
  },
  getConfirmedUser(id: string) {
    return client.get<ConfirmedUserResponse>(`/auth/confirmed/${id}`, {}, false);
  },
  editUser(id: string, data: UserForm) {
    return client.put<void>(`/users/edit/${id}`, withDataRequest(data));
  },
  getUserFormData(id?: string) {
    if (id) {
      return client.get<UserFormData>(`/users/${id}/form-data`);
    } else {
      return client.get<UserFormData>(`/users/form-data`);
    }
  },
  editProfile(data: ProfileEditForm) {
    return client.post<void>('/users/edit-profile', withDataRequest(data));
  },
  confirmEmailChange(data: URLParams) {
    return client.post<void>('/users/confirm-email-change', withDataRequest(data));
  },
  updatePassword(data: PasswordForm) {
    return client.post<void>('/users/update-password', withDataRequest(data));
  },
  setActiveAccountType(accountType: AccountTypes) {
    return client.put<void>('/users/set-active-account-type', withDataRequest({ accountType }));
  },
  getAccountEmailsSelectList(params: GetSelectList) {
    return client.post<GetSelectListResponse>('/users/get-account-emails-select-list', withDataRequest(params));
  },
  resendInvite(id: string) {
    return client.post<void>(`/users/${id}/resend-invite`);
  },
  getNotificationSettingsFormData() {
    return client.get<NotificationSettingsFormData>(`/users/notification-settings-form-data`);
  },
  editNotificationSettings(data: NotificationSettingsForm) {
    return client.put<void>('/users/edit-notification-settings', withDataRequest(data));
  },
  getProfileFormData() {
    return client.get<ProfileFormData>('/users/profile-form-data');
  },
  updatePhone(data: UpdatePhoneForm) {
    return client.put<void>('/users/update-phone', withDataRequest(data));
  },
  confirmPhoneChange(data: ChangeToken) {
    return client.post<void>('/users/confirm-phone-change', withDataRequest(data));
  },
  getAccountUsersSelectList(params: GetSelectList) {
    return client.post<GetSelectListResponse>('/users/get-account-users-select-list', withDataRequest(params));
  },
  getManagersForAssignSelectList(params: ManageableData<GetJobSeekerManagersSelectListFilters>) {
    return client.post<GetSelectListResponse>('/users/get-managers-for-assign-select-list', withDataRequest(params));
  },
};

export default userAPI;
