import {
  PASSWORD_REGEX,
  VALIDATION_INVALID_PASSWORD_CONFIRM_MESSAGE,
  VALIDATION_INVALID_PASSWORD_MESSAGE,
  VALIDATION_REQUIRED_PASSWORD_CONFIRMATION_MESSAGE,
  VALIDATION_REQUIRED_PASSWORD_MESSAGE,
} from 'utils/consts';
import * as yup from 'yup';

export default yup.object().shape({
  password: yup.string().matches(PASSWORD_REGEX, VALIDATION_INVALID_PASSWORD_MESSAGE)
    .required(VALIDATION_REQUIRED_PASSWORD_MESSAGE),
  repeatedPassword: yup.string().oneOf([yup.ref('password')], VALIDATION_INVALID_PASSWORD_CONFIRM_MESSAGE)
    .required(VALIDATION_REQUIRED_PASSWORD_CONFIRMATION_MESSAGE),
});
