import { FilterTypes, SaveOpportunityFiltersFormData, SaveOpportunityFiltersForm, GetOpportunitiesTabsFilters, SaveOpportunityFiltersBaseForm } from 'features/opportunities-filters';
import { client, withDataRequest } from 'utils/client';
import { GetSelectList, GetSelectListResponse, SelectItem } from './types';

const opportunityFiltersAPI = {
  save(data: SaveOpportunityFiltersForm, type: FilterTypes) {
    if (type === FilterTypes.Organization) return client.post<SelectItem>('/opportunity-filters/organization-save', withDataRequest(data));
    else return client.post<SelectItem>('/opportunity-filters/job-seeker-save', withDataRequest(data));
  },
  saveInitialEntry(data: SaveOpportunityFiltersBaseForm) {
    return client.post<void>('/opportunity-filters/job-seeker-save-initial-entry', withDataRequest(data));
  },
  getSaveData(opportunityFilterId: string | undefined, type: FilterTypes) {
    if (type === FilterTypes.Organization) {
      if (opportunityFilterId) return client.get<SaveOpportunityFiltersFormData>(`/opportunity-filters/${opportunityFilterId}/user-save-data`);
      else return client.get<SaveOpportunityFiltersFormData>(`/opportunity-filters/user-save-data`);
    } else {
      if (opportunityFilterId) return client.get<SaveOpportunityFiltersFormData>(`/opportunity-filters/${opportunityFilterId}/job-seeker-save-data`);
      else return client.get<SaveOpportunityFiltersFormData>(`/opportunity-filters/job-seeker-save-data`);
    }
  },
  getSelectList(params: GetSelectList, type: FilterTypes) {
    if (type === FilterTypes.Organization) return client.post<GetSelectListResponse>(`/opportunity-filters/get-organization-select-list`, withDataRequest(params));
    else return client.post<GetSelectListResponse>(`/opportunity-filters/get-job-seeker-select-list`, withDataRequest(params));
  },
  getData(opportunityFilterId: string, type: FilterTypes) {
    if (type === FilterTypes.Organization) return client.get<GetOpportunitiesTabsFilters>(`/opportunity-filters/${opportunityFilterId}/organization-data`);
    else return client.get<GetOpportunitiesTabsFilters>(`/opportunity-filters/${opportunityFilterId}/job-seeker-data`);
  },
  deleteByOrganization(id: string) {
    return client.delete<void>(`/opportunity-filters/${id}/organization`);
  },
  deleteByJobSeeker(id: string) {
    return client.delete<void>(`/opportunity-filters/${id}/job-seeker`);
  },
};

export default opportunityFiltersAPI;
