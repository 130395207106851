import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobSeekersAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { EmploymentStatusFormData, JobSeekerEmploymentStatusState } from './types';

export const getEmploymentStatusData = createAsyncThunk<EmploymentStatusFormData, void, RejectWithMessage>(
  'job-seeker-employment-status/get-data',
  async (_, { rejectWithValue }) => {
    try {
      return await jobSeekersAPI.getEmploymentStatusData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: JobSeekerEmploymentStatusState = {
  data: null,
  status: 'idle',
  error: null,
};

const jobSeekerEmploymentStatusSlice = createSlice({
  name: 'job-seeker-employment-status',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getEmploymentStatusData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEmploymentStatusData.fulfilled, (state, action: PayloadAction<EmploymentStatusFormData>) => {
      state.data = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getEmploymentStatusData.rejected, (state) => {
      state.data = null;
      state.status = 'rejected';
    });
  },
});

export const jobSeekerEmploymentStatusReducer = jobSeekerEmploymentStatusSlice.reducer;
