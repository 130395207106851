import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { employersAPI } from 'api';
import { getErrorMessage } from 'utils/error';
import { EmployerCreateState } from './types';
import { EmployerCreateFormData } from 'features/employers/types';

export const getCreateFormData = createAsyncThunk<EmployerCreateFormData, void, RejectWithMessage>(
  'employer-create/get-create-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await employersAPI.getCreateFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: EmployerCreateState = {
  status: 'idle',
  error: null,
  data: {
    formData: null,
  },
};

const employerCreateSlice = createSlice({
  name: 'employer-create',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getCreateFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCreateFormData.fulfilled, (state, action: PayloadAction<EmployerCreateFormData>) => {
      state.status = 'fulfilled';
      state.data.formData = action.payload;
    });
    builder.addCase(getCreateFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const employerCreateReducer = employerCreateSlice.reducer;
