import { FC } from 'react';
import { ErrorMessage } from 'formik';

interface FormErrorProps {
  name: string;
  className?: string;
};

const FormError: FC<FormErrorProps> = ({ name, className = '' }: FormErrorProps) => (
  <ErrorMessage 
    name={name} 
    render={(message: string) => (
      <div className={(`invalid-feedback ${className}`).trim()}>
        {message}
      </div>
    )}
  />
);

export default FormError;