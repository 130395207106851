import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialRequestData from 'features/initial-request-data';
import { getErrorMessage } from 'utils/error';
import { DiscoverEmployerFiltersData, DiscoverEmployersState, DiscoverEmployerTableViewResponse, GetDiscoverEmployersTableFilters } from './types';
import { Paging } from 'api/types';
import { RootState } from 'app/root-reducer';
import { RejectWithMessage } from 'features/types';
import { employersAPI } from 'api';

export const getDiscoverTable = createAsyncThunk<DiscoverEmployerTableViewResponse, void, RejectWithMessage>(
  'discover-employers/get-discover-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.discoverEmployers.requestData;
      return await employersAPI.getTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const discoverTableFiltersData = createAsyncThunk<DiscoverEmployerFiltersData, void, RejectWithMessage>(
  'discover-employers/discover-table-filters-data',
  async (_, { rejectWithValue }) => {
    try {
      return await employersAPI.getTableFilters();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const initialStateRequest = {
  ...initialRequestData,
  filters: {},
};

const initialState: DiscoverEmployersState = {
  status: 'idle',
  requestData: initialStateRequest,
  data: {
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
    filtersData: null,
  },
  error: null,
};

const discoverEmployers = createSlice({
  name: 'discoverEmployers',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<GetDiscoverEmployersTableFilters>) => {
      state.requestData.filters = { ...state.requestData.filters, ...action.payload };
      state.requestData.paging = initialRequestData.paging;
    },
    resetRequestData: (state) => {
      state.requestData = initialStateRequest;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscoverTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getDiscoverTable.fulfilled, (state, action: PayloadAction<DiscoverEmployerTableViewResponse>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getDiscoverTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });

    builder.addCase(discoverTableFiltersData.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(discoverTableFiltersData.fulfilled, (state, action: PayloadAction<DiscoverEmployerFiltersData>) => {
      state.data.tableDataIsLoading = false;
      state.data.filtersData = action.payload;
    });
    builder.addCase(discoverTableFiltersData.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  }
});

export const { setPage, setFilters, resetRequestData, resetTableData } = discoverEmployers.actions;
export const discoverEmployersReducer = discoverEmployers.reducer;
