import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage, TableDataResponse } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { RootState } from 'app/root-reducer';
import { Paging } from 'api/types';
import { DiscoverResourceTableView, DiscoverResourceState } from './types';
import { resourcesAPI } from 'api';
import { GetResourcesTable, GetResourcesTableFilters } from 'features/resources/types';

export const getDiscoverResourcesTable = createAsyncThunk<TableDataResponse<DiscoverResourceTableView>, void, RejectWithMessage>(
  'discover-resources/get-table',
  async(_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.discoverResources.requestData;
      return resourcesAPI.getDiscoverTable(requestData)
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const initialStateRequest: GetResourcesTable = {
  paging: {
    page: 1,
    pageSize: 20,
  },
  filters: {},
};

const initialState: DiscoverResourceState = {
  status: 'idle',
  error: null,
  requestData: initialStateRequest,
  data: {
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  }
};

const discoverResourcesSlice = createSlice({
  name: 'discover-resources',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    setFilters: (state, action: PayloadAction<Partial<GetResourcesTableFilters>>) => {
      state.requestData.filters = { ...action.payload };
      state.requestData.paging = initialStateRequest.paging;
    },
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscoverResourcesTable.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getDiscoverResourcesTable.fulfilled, (state, action: PayloadAction<TableDataResponse<DiscoverResourceTableView>>) => {
      state.status = 'fulfilled';
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
      state.error = null;
    });
    builder.addCase(getDiscoverResourcesTable.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { setPage, setFilters, resetRequestData, resetTableData } = discoverResourcesSlice.actions;

export const discoverResourcesReducer = discoverResourcesSlice.reducer;
