import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './Header.scss';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import SvgIcon from 'components/SvgIcon';
import { ROUTES } from 'app/routes';
import { deleteSession, selectIsJobSeekerAuthenticated, selectJobSeeker } from 'features/job-seeker-auth';
import InvitationModal from "../../../pages/job-seekers/components/InvitationModal";

type Props = {
  toggleSidebar(): void;
};

const JobSeekerHeader = ({ toggleSidebar }: Props) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsJobSeekerAuthenticated);
  const jobSeeker = useAppSelector(selectJobSeeker);
  const history = useHistory();

  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  const toggleDropdown = (): void => setDropdownIsOpen(!dropdownIsOpen);
  const logOut = (): void => dispatch(deleteSession());

  const [invitationModalIsOpen, setInvitationModalIsOpen] = useState<boolean>(false);
  const toggleInvitationModal = () => { setInvitationModalIsOpen(value => !value) };

  return (
    <>
      <Navbar className="navbar-header px-0" dark expand="md">
        <button className="navbar-toggler" onClick={toggleSidebar}>
          <SvgIcon name="sidebar/toggle-sidebar" />
        </button>
        <NavbarBrand to={ROUTES.JOB_SEEKER.OPPORTUNITIES} tag={Link}>
          <SvgIcon name="logo" className="logo-with-text" title="WorkerSpring" />
          <SvgIcon name="logo-no-text" className="logo-no-text" title="WorkerSpring" />
        </NavbarBrand>
        <Nav className="ms-auto" navbar>
          {(isAuthenticated && jobSeeker) && <>
            <NavItem>
              <button className="btn btn-icon btn-secondary btn-invite-job-seekers" onClick={() => {
                setInvitationModalIsOpen(true);
              }}>
                <SvgIcon name="invite" title="Invite others" />
                Invite others
              </button>
            </NavItem>
            <NavItem>
              <Dropdown isOpen={dropdownIsOpen} toggle={toggleDropdown}>
                <DropdownToggle>
                  <div className="user-profile">
                    <SvgIcon name="user-icon" className="user-icon" />
                    <div className="btn-user-profile">
                      <div>
                        <div className="navbar-username" title={jobSeeker.name}>{jobSeeker.name}</div>
                      </div>
                      <SvgIcon name="chevron" className="dropdown-arrow" />
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="job-seeker" end={true}>
                  <DropdownItem className="menu-item" onClick={() => history.push(ROUTES.JOB_SEEKER.PROFILE_PAGE)}>
                    My profile
                  </DropdownItem>
                  <DropdownItem className="menu-item signout-item" onClick={logOut}>
                    Sign out <SvgIcon name="signout" />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </>}
        </Nav>
      </Navbar>
      <InvitationModal isOpen={invitationModalIsOpen} toggleModal={toggleInvitationModal} isJobSeeker={true} />
    </>
  );
};

export default JobSeekerHeader;
