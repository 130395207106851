import { RestorePasswordData } from 'features/auth/types';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import schema from './schema';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SvgIcon from 'components/SvgIcon';
import { fieldInvalidClass } from 'utils/form';
import FormError from 'components/FormError';
import { ROUTES } from 'app/routes';
import PasswordValidation from 'components/PasswordValidation';
import { removeWhitespaces } from 'utils/string';

type Props = {
  onFinish: (data: RestorePasswordData, actions: FormikHelpers<RestorePasswordData>) => Promise<void>;
};

const RestorePasswordForm = ({ onFinish }: Props) => {

  const { restoreToken } = useParams<{ restoreToken: string }>();
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState<boolean>(false);

  return (
    <div className="form-container pre-login-form-container">
      <h4 className="form-title">Create new password</h4>
      <p className="description form-wrap-text">Please enter the new password</p>
      <Formik<RestorePasswordData>
        initialValues={{
          password: '',
          repeatedPassword: '',
          restoreToken: restoreToken,
        }}
        onSubmit={onFinish}
        validationSchema={schema}
      >
        {({ isSubmitting, touched, errors, setFieldValue }) => (
          <Form>
            <div className="form-wrap">
              <div className="form-group form-group-password">
                <label className="form-label" htmlFor="password">Password</label>
                <div className={`password-wrap ${fieldInvalidClass<RestorePasswordData>(touched, errors, 'password')}`.trim()}>
                  <Field
                    name="password"
                    id="password"
                    className={`form-control ${fieldInvalidClass<RestorePasswordData>(touched, errors, 'password')}`.trim()}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter password"
                    onChange={(event: React.ChangeEvent) => {
                      setFieldValue('password', removeWhitespaces((event.target as HTMLInputElement).value));
                      setPassword(removeWhitespaces((event.target as HTMLInputElement).value));
                    }}
                  />
                  <div
                    onClick={() => setShowPassword((show: boolean) => !show)}
                    className="toggle-icon"
                  >
                    <SvgIcon
                      name={showPassword ? 'eye-closed' : 'eye'}
                    />
                  </div>
                </div>
                <FormError name="password" />
                <PasswordValidation password={password} />
              </div>
              <div className="form-group form-group-password">
                <label className="form-label" htmlFor="repeatedPassword">Confirm password</label>
                <div className={`password-wrap ${fieldInvalidClass<RestorePasswordData>(touched, errors, 'repeatedPassword')}`.trim()}>
                  <Field
                    name="repeatedPassword"
                    id="repeatedPassword"
                    className={`form-control ${fieldInvalidClass<RestorePasswordData>(touched, errors, 'repeatedPassword')}`.trim()}
                    type={showRepeatedPassword ? 'text' : 'password'}
                    placeholder="Confirm password"
                    onChange={(event: React.ChangeEvent) => {
                      setFieldValue('repeatedPassword', removeWhitespaces((event.target as HTMLInputElement).value));
                    }}
                  />
                  <div
                    onClick={() => setShowRepeatedPassword((show: boolean) => !show)}
                    className="toggle-icon"
                  >
                    <SvgIcon
                      name={showRepeatedPassword ? 'eye-closed' : 'eye'}
                    />
                  </div>
                </div>
                <FormError name="repeatedPassword" />
              </div>
            </div>
            <div className="d-grid submit-wrap submit-wrap-sticky">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                Save new password
              </button>
              <Link className="btn back-btn btn-icon" to={ROUTES.SIGN_IN}> <SvgIcon name="back-gray" className="mt-0" /> Back</Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RestorePasswordForm;
