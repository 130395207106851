import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import jobsAPI from 'api/jobs';
import { JobEmployerFormData, JobFormState, JobOrganizationFormData } from './types';

export const getEmployerFormData = createAsyncThunk<JobEmployerFormData, string | undefined, RejectWithMessage>(
  'job-form/get-employer-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await jobsAPI.getEmployerFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getOrganizationFormData = createAsyncThunk<JobOrganizationFormData, string | undefined, RejectWithMessage>(
  'job-form/get-organization-form-data',
  async (id, { rejectWithValue }) => {
    try {
      return await jobsAPI.getOrganizationFormData(id);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: JobFormState = {
  status: 'idle',
  error: null,
  data: {
    employerFormData: null,
    organizationFormData: null,
  }
};

const jobFormSlice = createSlice({
  name: 'job-form',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployerFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEmployerFormData.fulfilled, (state, action: PayloadAction<JobEmployerFormData>) => {
      state.status = 'fulfilled';
      state.data.employerFormData = action.payload;
    });
    builder.addCase(getEmployerFormData.rejected, (state) => {
      state.status = 'rejected';
    });

    builder.addCase(getOrganizationFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getOrganizationFormData.fulfilled, (state, action: PayloadAction<JobOrganizationFormData>) => {
      state.status = 'fulfilled';
      state.data.organizationFormData = action.payload;
    });
    builder.addCase(getOrganizationFormData.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const { resetState } = jobFormSlice.actions;

export const jobFormReducer = jobFormSlice.reducer;
