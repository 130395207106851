import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI } from 'api';
import axios from 'axios';
import { UserTypes, RejectWithError } from 'features/types';
import { tokenStorage } from 'services';
import { JobSeekerCompleteSignUpForm, JobSeekersInvitationState, ProvideTheDetailsFormData } from './types';

export const jobSeekerCompleteSignUp = createAsyncThunk<void, JobSeekerCompleteSignUpForm, RejectWithError>(
  'provide-the-details/job-seeker-complete-sign-up',
  async (data, { rejectWithValue }) => {
    try {
      const { accessToken, refreshToken } = await authAPI.jobSeekerCompleteSignUp(data);
      tokenStorage.init(accessToken, refreshToken, UserTypes.JobSeeker);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error);
      } else {
        return rejectWithValue(error as Error);
      }
    }
  }
);

const initialState: JobSeekersInvitationState = {
  status: 'idle',
  formData: null,
  error: null,
};

const provideTheDetailsSlice = createSlice({
  name: 'provideTheDetails',
  initialState,
  reducers: {
    setFormData: (state, action: PayloadAction<ProvideTheDetailsFormData>) => {
      state.formData = action.payload;
    },
    resetFormData: (state) => {
      state.formData = null;
    },
  },
});

export const { setFormData } = provideTheDetailsSlice.actions;
export const provideTheDetailsReducer = provideTheDetailsSlice.reducer;
