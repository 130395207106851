import { Redirect } from 'react-router-dom';
import { ROUTES } from 'app/routes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { deleteJobSeekerLocationFrom } from 'features/job-seeker-auth';
import { RootState } from 'app/root-reducer';
import { useRememberJobSeekerApplyJobId } from 'hooks';

const JobSeekerRedirectToHomePage = () => {
  const dispatch = useAppDispatch();
  const { from } = useAppSelector((state: RootState) => state.jobSeekerAuth);

  useRememberJobSeekerApplyJobId();

  if (from) {
    setTimeout(() => { dispatch(deleteJobSeekerLocationFrom()); }, 0);
    return <Redirect to={from} />;
  }

  return <Redirect to={ROUTES.JOB_SEEKER.OPPORTUNITIES} />
};

export default JobSeekerRedirectToHomePage;
