import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authAPI } from "api";
import { AxiosError } from "axios";
import { RejectWithError } from "features/types";
import { JobSeekerSignUpSendCode, JobSeekersInvitationState, Invitation, InvitationSteps } from "./types";

export const jobSeekerSignUpSendCode = createAsyncThunk<JobSeekerSignUpSendCode, JobSeekerSignUpSendCode, RejectWithError>(
  'job-seekers-invitation/sign-up-send-code',
  async (data, { rejectWithValue }) => {
    try {
      await authAPI.jobSeekerSignUpSendCode(data);
      return data;
    } catch (error) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const invitation: Invitation = {
  step: 0,
  email: null,
  token: null,
};

const initialState: JobSeekersInvitationState = {
  status: 'idle',
  invitation,
  error: null,
};

const jobSeekerInvitationSlice = createSlice({
  name: 'jobSeekersInvitation',
  initialState,
  reducers: {
    setInvitationStep: (state, action) => {
      state.invitation.step = action.payload;
    },
    resetInvitationData: (state) => {
      state.invitation = invitation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(jobSeekerSignUpSendCode.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(jobSeekerSignUpSendCode.fulfilled, (state, action: PayloadAction<JobSeekerSignUpSendCode>) => {
      state.status = 'fulfilled';
      state.error = null;
      state.invitation.step = InvitationSteps.CONFIRMATION_CODE;
      state.invitation.email = action.payload.email;
      state.invitation.token = action.payload.token ?? null;
    });
    builder.addCase(jobSeekerSignUpSendCode.rejected, (state, action) => {
      state.status = 'rejected';
    });
  }
});

export const { setInvitationStep, resetInvitationData } = jobSeekerInvitationSlice.actions;
export const jobSeekerInvitationReducer = jobSeekerInvitationSlice.reducer;
