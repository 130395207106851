import { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { getCurrentUser } from 'features/auth';
import tokenStorage from 'services/token-storage';
import { UserTypes } from 'features/types';
import { getCurrentJobSeeker } from 'features/job-seeker-auth';

const useCheckAuth = (): boolean => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuth = async (): Promise<void> => {
      if (tokenStorage.getAccessToken()) {
        if (tokenStorage.getAccountType() === UserTypes.User) {
          await dispatch(getCurrentUser());
        } else if (tokenStorage.getAccountType() === UserTypes.JobSeeker) {
          await dispatch(getCurrentJobSeeker());
        }
      }

      setIsLoading(false);
    };

    checkAuth();
  }, [dispatch]);

  return isLoading;
};

export default useCheckAuth;
