import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppRoute, ROUTES } from 'app/routes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectIsAuthenticated, rememberLocation, selectUser } from 'features/auth';
import { CompanyStatuses } from 'features/types';
import { AppLayout } from 'layout/AppLayout';
import { ALLOW_SCHEDULE_APPROVAL_INTRODUCTORY_SESSION } from 'utils/consts';

const UserPrivateRoute = (props: Omit<AppRoute, 'accountType'>) => {
  const { component: Component, allowedRoles, allowedCompanyStatuses, hideProgramsCheck, ...rest } = props;
  const user = useAppSelector(selectUser);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const location = useLocation();
  const dispatch = useAppDispatch();

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthenticated) {
          dispatch(rememberLocation(location.pathname + location.search));
          return <Redirect to={ROUTES.SIGN_IN} />;
        }

        if (user && allowedRoles && allowedRoles.length && !allowedRoles.includes(user.role)) {
          return <Redirect to="/" />;
        }

        if (user && allowedCompanyStatuses && allowedCompanyStatuses.length && !allowedCompanyStatuses.includes(user.activeAccountStatus)) {
          switch (user.activeAccountStatus) {
            case CompanyStatuses.EmployerCompleted:
              return <Redirect to={ROUTES.JOB_POSTS} />;
            case CompanyStatuses.OrganizationCompleted:
              return <Redirect to={ROUTES.PROGRAM_PAGE} />;
            case CompanyStatuses.OrganizationDisabled:
            case CompanyStatuses.EmployerDisabled:
              return <Redirect to={ALLOW_SCHEDULE_APPROVAL_INTRODUCTORY_SESSION ? ROUTES.DASHBOARD : ROUTES.ACCOUNT_SETUP} />;
            default:
              return <Redirect to="/" />
          }
        }

        if (hideProgramsCheck && user?.employer?.isProgramsHidden) {
          return <Redirect to="/" />;
        }

        return (
          <AppLayout>
            <Component />
          </AppLayout>
        );
      }}
    />
  );
};

export default UserPrivateRoute;
