import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { opportunitiesAPI } from "api";
import { Paging, Tab } from "api/types";
import { RootState } from "app/root-reducer";
import { RejectWithMessage } from "features/types";
import { getErrorMessage } from "utils/error";
import { OpportunitiesState, OpportunitiesTableResponse } from "./types";

export const getOrganizationOpportunitiesTabs = createAsyncThunk<Tab[], void, RejectWithMessage>(
  'opportunities/get-opportunities-tabs',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = state.opportunitiesFilters.currentFilters;
      return await opportunitiesAPI.getOrganizationOpportunitiesTabs(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getOrganizationOpportunitiesTable = createAsyncThunk<OpportunitiesTableResponse, void, RejectWithMessage>(
  'opportunities/get-opportunities-table',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState();
      const requestData = { ...state.opportunities.requestData, filters: state.opportunitiesFilters.currentFilters };
      return await opportunitiesAPI.getOrganizationOpportunitiesTable(requestData);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: OpportunitiesState = {
  status: 'idle',
  requestData: {
    paging: {
      page: 1,
      pageSize: 20,
    },
  },
  data: {
    tabsIsLoading: false,
    tabs: [],
    tableDataIsLoading: false,
    tableData: {
      rows: [],
      totalCount: 0,
      noResult: false,
    },
  },
  error: null,
};

const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.requestData = { ...initialState.requestData };
    },
    setPage: (state, action: PayloadAction<Paging>) => {
      state.requestData.paging.page = action.payload.page;
      state.requestData.paging.pageSize = action.payload.pageSize;
    },
    resetTableData: (state) => {
      state.data.tableData = initialState.data.tableData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationOpportunitiesTabs.pending, (state) => {
      state.data.tabsIsLoading = true;
    });
    builder.addCase(getOrganizationOpportunitiesTabs.fulfilled, (state, action: PayloadAction<Tab[]>) => {
      state.data.tabsIsLoading = false;
      state.data.tabs = action.payload;
    });
    builder.addCase(getOrganizationOpportunitiesTabs.rejected, (state) => {
      state.data.tabsIsLoading = false;
    });

    builder.addCase(getOrganizationOpportunitiesTable.pending, (state) => {
      state.data.tableDataIsLoading = true;
    });
    builder.addCase(getOrganizationOpportunitiesTable.fulfilled, (state, action: PayloadAction<OpportunitiesTableResponse>) => {
      state.data.tableDataIsLoading = false;
      state.data.tableData = action.payload;
      state.data.tableData.noResult = state.data.tableData.rows.length === 0;
    });
    builder.addCase(getOrganizationOpportunitiesTable.rejected, (state) => {
      state.data.tableDataIsLoading = false;
    });
  },
});

export const { resetRequestData, setPage, resetTableData } = opportunitiesSlice.actions;
export const opportunitiesReducer = opportunitiesSlice.reducer;
