import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { opportunitiesAPI, opportunityFiltersAPI } from "api";
import { RejectWithMessage } from "features/types";
import { getErrorMessage } from "utils/error";
import { EnhancedFiltersForm, FilterTypes, GetOpportunitiesTableFilters, GetOpportunitiesTabsFilters, OpportunitiesFiltersData, OpportunitiesFiltersState, OpportunityTabs, SaveOpportunityFiltersFormData } from "./types";

export const getFiltersData = createAsyncThunk<OpportunitiesFiltersData, void, RejectWithMessage>(
  'opportunities-filters/get-filters-data',
  async (_, { rejectWithValue }) => {
    try {
      return await opportunitiesAPI.getFiltersData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getFilterData = createAsyncThunk<GetOpportunitiesTabsFilters, { opportunityFilterId: string, type: FilterTypes }, RejectWithMessage>(
  'opportunities/get-filter-data',
  async (params, { rejectWithValue }) => {
    try {
      return await opportunityFiltersAPI.getData(params.opportunityFilterId, params.type);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getSaveData = createAsyncThunk<SaveOpportunityFiltersFormData, { opportunityFilterId?: string, type: FilterTypes }, RejectWithMessage>(
  'opportunities/get-save-data',
  async (params, { rejectWithValue }) => {
    try {
      return await opportunityFiltersAPI.getSaveData(params.opportunityFilterId, params.type);
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: OpportunitiesFiltersState = {
  status: 'idle',
  currentFilters: { tab: OpportunityTabs.Open, location: null },
  selectedEnhancedFiltersCount: 0,
  anyFilterSelected: false,
  data: {
    filters: null,
    saveOpportunityFiltersFormData: null,
  },
  error: null,
};

const opportunitiesFiltersSlice = createSlice({
  name: 'opportunities-filters',
  initialState,
  reducers: {
    resetRequestData: (state) => {
      state.currentFilters = initialState.currentFilters;
      state.selectedEnhancedFiltersCount = initialState.selectedEnhancedFiltersCount;
      state.anyFilterSelected = initialState.anyFilterSelected
    },
    setFilters: (state, action: PayloadAction<Partial<GetOpportunitiesTableFilters> | EnhancedFiltersForm>) => {
      const newFilters = { ...state.currentFilters, ...action.payload };
      state.currentFilters = newFilters;
      state.selectedEnhancedFiltersCount = Object
        .entries(newFilters)
        .filter(([key, value]) => !['tab', 'employer', 'location', 'text', 'fullAddress'].includes(key))
        .filter(([key, value]) => (value as string | string[])?.length > 0).length;
      state.anyFilterSelected = Object
        .entries(newFilters)
        .filter(([key, value]) => !['tab', 'employer', 'location'].includes(key))
        .filter(([key, value]) => (value as string | string[])?.length > 0).length > 0;
    },
    setTab: (state, action: PayloadAction<string>) => {
      state.currentFilters.tab = action.payload;
    },
    resetSaveOpportunityFiltersFormData: (state) => {
      state.data.saveOpportunityFiltersFormData = initialState.data.saveOpportunityFiltersFormData;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getFiltersData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFiltersData.fulfilled, (state, action: PayloadAction<OpportunitiesFiltersData>) => {
      state.status = 'fulfilled';
      state.data.filters = action.payload;
      state.error = null;
    });
    builder.addCase(getFiltersData.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(getFilterData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFilterData.fulfilled, (state, action: PayloadAction<GetOpportunitiesTabsFilters>) => {
      state.status = 'fulfilled';
      const newFilters = { ...action.payload, tab: state.currentFilters.tab, employerId: action.payload.employer?.value };
      state.currentFilters = newFilters;
      state.selectedEnhancedFiltersCount = Object
        .entries(newFilters)
        .filter(([key, value]) => !['tab', 'employer', 'location', 'text', 'fullAddress'].includes(key))
        .filter(([key, value]) => (value as string | string[])?.length > 0).length;
      state.anyFilterSelected = Object
        .entries(newFilters)
        .filter(([key, value]) => !['tab', 'employer', 'location'].includes(key))
        .filter(([key, value]) => (value as string | string[])?.length > 0).length > 0;
      state.error = null;
    });
    builder.addCase(getFilterData.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(getSaveData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getSaveData.fulfilled, (state, action: PayloadAction<SaveOpportunityFiltersFormData>) => {
      state.status = 'fulfilled';
      state.data.saveOpportunityFiltersFormData = action.payload;
      state.error = null;
    });
    builder.addCase(getSaveData.rejected, (state) => {
      state.status = 'rejected';
    });
  },
});

export const { resetRequestData, setFilters, setTab, resetSaveOpportunityFiltersFormData, resetState } = opportunitiesFiltersSlice.actions;
export const opportunitiesFiltersReducer = opportunitiesFiltersSlice.reducer;
