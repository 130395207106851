import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { organizationAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { OrganizationEmployersState, OrganizationSettingsDetailsForm } from 'features/organizations/types';

export const getOrganizationDetails = createAsyncThunk<OrganizationSettingsDetailsForm, void, RejectWithMessage>(
  'organization/get-organization-details',
  async (_, { rejectWithValue }) => {
    try {
      return await organizationAPI.getOrganizationDetails();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: OrganizationEmployersState = {
  status: 'idle',
  data: {
    selectedOrganization: null,
  },
  error: null,
};

const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getOrganizationDetails.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'fulfilled';
      state.data.selectedOrganization = action.payload;
      state.error = null;
    });
    builder.addCase(getOrganizationDetails.rejected, (state) => {
      state.status = 'rejected';
    });
  },
});

export const organizationReducer = organizationSlice.reducer;
