export const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

export const removeWhitespaces = (text: string): string => text.replaceAll(' ', '');

export const capitaliseText = (text: string): string => {
  const names: string[] = text.split(' ');
  for (let i = 0; i < names.length; i++) {
    names[i] = capitalize(names[i].trim());
  }
  text = names.join(' ');
  return text;
};
