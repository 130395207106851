import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authAPI, userAPI } from 'api';
import { AppThunk } from 'app/store';
import { RootState } from 'app/root-reducer';
import { UserTypes, RejectWithMessage } from 'features/types';
import { tokenStorage } from 'services';
import { AuthState, User, RefreshTokenResponse } from './types';
import { getErrorMessage } from 'utils/error';

export const renewAccessToken = createAsyncThunk<RefreshTokenResponse, void, RejectWithMessage>(
  'auth/renew-access-token',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = tokenStorage.getRefreshToken();

      if (!refreshToken) {
        throw Error('Session token does not exist');
      }
      const data = await authAPI.renewAccessToken(refreshToken);
      const { accessToken } = data;
      tokenStorage.setAccessToken(accessToken);
      return data;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getCurrentUser = createAsyncThunk<User, void, RejectWithMessage>(
  'auth/get-current-user',
  async (_, { rejectWithValue }) => {
    try {
      return await userAPI.me();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const setAccessToken = createAsyncThunk<any, any, RejectWithMessage>(
  'auth/set-access-token',
  async (data, { rejectWithValue }) => {
    try {
      const { accessToken, refreshToken } = data;
      tokenStorage.init(accessToken, refreshToken, UserTypes.User);
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  status: 'idle',
  error: null,
  from: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = false;
      state.user = null;
      state.status = 'idle';
      state.error = null;
    },
    rememberLocation: (state, action: PayloadAction<string>) => {
      state.from = action.payload;
    },
    deleteLocationFrom: (state) => {
      state.from = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(renewAccessToken.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(renewAccessToken.fulfilled, (state) => {
      state.status = 'fulfilled';
      state.error = null;
    });

    builder.addCase(renewAccessToken.rejected, (state, action) => {
      state.status = 'rejected';

      if (action.payload) {
        state.error = action.payload;
      }
    });

    builder.addCase(getCurrentUser.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.isAuthenticated = action.payload ? true : false;
      state.user = action.payload;
      state.status = 'fulfilled';
      state.error = null;
    });

    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.status = 'rejected';

      if (action.payload) {
        state.error = action.payload;
      }
    });

    builder.addCase(setAccessToken.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(setAccessToken.fulfilled, (state) => {
      state.isAuthenticated = true;
      state.status = 'fulfilled';
      state.error = null;
    });

    builder.addCase(setAccessToken.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.status = 'rejected';

      if (action.payload) {
        state.error = action.payload;
      }
    });
  },
});

export const { signOut } = authSlice.actions;

export const deleteSession = (reason: string = 'Signed out'): AppThunk => {
  return (dispatch) => {
    tokenStorage.clear();
    dispatch(signOut(reason));
  };
};

export const selectIsAuthenticated = (state: RootState): boolean => state.auth.isAuthenticated;
export const selectUser = (state: RootState): User | null => state.auth.user;
export const { rememberLocation, deleteLocationFrom } = authSlice.actions;

export const authReducer = authSlice.reducer;
