import { Redirect, Route, useLocation } from 'react-router-dom';
import { AppRoute, ROUTES } from 'app/routes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { rememberJobSeekerLocation, selectIsJobSeekerAuthenticated } from 'features/job-seeker-auth';
import { JobSeekerAppLayout } from 'layout/AppLayout';
import { SignInSections } from 'features/types';

const JobSeekerPrivateRoute = (props: Omit<AppRoute, 'accountType'>) => {
  const { component: Component, allowedRoles, allowedCompanyStatuses, ...rest } = props;
  const isAuthenticated = useAppSelector(selectIsJobSeekerAuthenticated);
  const location = useLocation();
  const dispatch = useAppDispatch();

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthenticated) {
          dispatch(rememberJobSeekerLocation(location.pathname));
          return <Redirect to={ROUTES.SIGN_IN_DYNAMIC(SignInSections.JobSeekerSignIn)} />;
        }

        return (
          <JobSeekerAppLayout>
            <Component />
          </JobSeekerAppLayout>
        );
      }}
    />
  );
};

export default JobSeekerPrivateRoute;
