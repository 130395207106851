import { toast, ToastOptions } from "react-toastify";
import SvgIcon from "components/SvgIcon";
import toastData from "components/Toasts/CustomToast";

type Params = {
  body: string | undefined;
  options?: ToastOptions;
};

export namespace customToast {
  export function success(params: Params) {
    return toast(toastData('Success', { class: '--success', body: params.body }), { icon: SvgIcon({ name: 'toast-success' }), ...params.options });
  };
  export function error(params: Params) {
    return toast(toastData('Error', { class: '--error', body: params.body }), { icon: SvgIcon({ name: 'danger' }), ...params.options });
  }
}

export default customToast;
