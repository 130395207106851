import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userAPI } from 'api';
import { RejectWithMessage } from 'features/types';
import { getErrorMessage } from 'utils/error';
import { ProfileFormData, UserProfileState } from './types';

export const getProfileFormData = createAsyncThunk<ProfileFormData, void, RejectWithMessage>(
  'user-profile/get-profile-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await userAPI.getProfileFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

const initialState: UserProfileState = {
  formData: null,
  status: 'idle',
  error: null,
};

const userProfileSlice = createSlice({
  name: 'user-profile',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileFormData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getProfileFormData.fulfilled, (state, action: PayloadAction<ProfileFormData>) => {
      state.formData = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getProfileFormData.rejected, (state) => {
      state.formData = null;
      state.status = 'rejected';
    });
  },
});

export const userProfileReducer = userProfileSlice.reducer;
