import { FormikTouched, FormikErrors } from 'formik';
import { focusToFirstError } from 'utils/error';
import React from 'react';

export interface ValidationError {
  property: string;
  children: Array<ValidationError>;
  constraints: {
    [key: string]: string;
  }
};

export function fieldInvalidClass<T>(touched: FormikTouched<T> | undefined, errors: FormikErrors<T> | undefined, name: keyof T): string {
  return touched && errors && touched[name] && errors[name] ? 'is-invalid' : '';
};

export function fieldInvalidClassArray<T>(touched: FormikTouched<T>[] | undefined, errors: FormikErrors<T>[] | undefined, name: keyof T, index: number): string {
  return touched && touched[index] && errors && errors[index] && touched[index][name] && errors[index][name] ? 'is-invalid' : '';
};

export function applyValidationErrors(errors: ValidationError[] | undefined, setFieldError: (field: string, message: string | undefined) => void, prefix?: string, setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void): void {
  if (!errors) return;
  
  const errorFields = errors.map((error: ValidationError) => {
    const { children, property, constraints } = error;
    const fieldProperty: string = prefix ? `${prefix}.${property}` : property;

    if (children.length === 0) {
      Object.keys(constraints).forEach((constraint: string) => {
        setFieldError(fieldProperty, constraints[constraint]);
        setFieldTouched && setFieldTouched(fieldProperty, true, false);
      });
    } else {
      applyValidationErrors(children, setFieldError, fieldProperty);
    }

    return fieldProperty;
  });
  focusToFirstError(errorFields);
};

export async function handleValidation(
  validationFunction: (values?: any) => Promise<FormikErrors<typeof formValues>>,
  formValues: any,
  setFieldError: (field: string, message: string | undefined) => void,
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
) {
  const validationErrors: FormikErrors<any> = await validationFunction(formValues);
  if (Object.keys(validationErrors).length > 0) {
    Object.entries(validationErrors).forEach(([key, value]) => {
      setFieldError(key, value as string);
      setFieldTouched(key);
    });
    focusToFirstError(Object.keys(validationErrors));
  } else {
    handleSubmit();
  }
}