import { DiscoverResourceTableView } from 'features/discover-resources';
import { DiscoverResourceByOrganizationTableView } from 'features/discover-resources-by-organization';
import { ResourceJobSeekerTableView } from 'features/job-seeker-resources/types';
import { ResourceDetails } from 'features/resource-details';
import { ResourceShareForm, ResourceShareFormData } from 'features/resource-share';
import { GetResourcesTable, ResourceFormData, ResourceTableView } from 'features/resources/types';
import { TableDataResponse } from 'features/types';
import { client, download, withDataRequest } from 'utils/client';

const resourcesAPI = {
  getFormData(resourceId: string | undefined) {
    let endpoint = `/resources/form-data`;
    if (resourceId) endpoint = `/resources/${resourceId}/form-data`;
    return client.get<ResourceFormData>(endpoint);
  },
  addJob(data: FormData) {
    return client.post<void>('/resources/add', withDataRequest(data));
  },
  editJob(data: FormData, resourceId: string) {
    return client.put<void>(`/resources/${resourceId}/edit`, withDataRequest(data));
  },
  getTable(params: GetResourcesTable) {
    return client.post<TableDataResponse<ResourceTableView>>('/resources/get-table', withDataRequest(params));
  },
  getDetails(resourceId: string, discover: boolean) {
    if (discover) return client.get<ResourceDetails>(`/resources/${resourceId}/discover-details`, undefined, false);
    else return client.get<ResourceDetails>(`/resources/${resourceId}/details`);
  },
  downloadFile(resourceId: string, discover: boolean) {
    if (discover) return download(`/resources/${resourceId}/discover-download-file`, undefined, false);
    else return download(`/resources/${resourceId}/download-file`);
  },
  getJobSeekerTable(params: GetResourcesTable) {
    return client.post<TableDataResponse<ResourceJobSeekerTableView>>('/resources/get-job-seeker-table', withDataRequest(params));
  },
  getDiscoverTable(params: GetResourcesTable) {
    return client.post<TableDataResponse<DiscoverResourceTableView>>('/resources/get-discover-table', withDataRequest(params), false);
  },
  getDiscoverByOrganizationTable(params: GetResourcesTable) {
    return client.post<TableDataResponse<DiscoverResourceByOrganizationTableView>>('/resources/get-discover-by-organization-table', withDataRequest(params));
  },
  getShareFormData(resourceId: string) {
    return client.get<ResourceShareFormData>(`/resources/${resourceId}/share-form-data`);
  },
  share(params: ResourceShareForm, resourceId: string) {
    return client.put<void>(`/resources/${resourceId}/share`, withDataRequest(params));
  },
  unshare(resourceId: string) {
    return client.put<void>(`/resources/${resourceId}/unshare`);
  },
};

export default resourcesAPI;
