import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectWithError, RejectWithMessage } from 'features/types';
import { employersAPI } from 'api';
import axios from 'axios';
import { RootState } from 'app/root-reducer';
import {
  EmployerFocusAreaForm,
  AccountSetupStep,
  EmployerSetupState,
  EmployerDetailsResponse,
  EmployerFocusAreaFormData,
  EmployerSettingsDetailsFormResponse,
} from 'features/employers/types';
import { AccountSetupSteps, PrimaryInfo } from 'features/account-setup/types';
import { getErrorMessage } from 'utils/error';

export const getEmployerName = createAsyncThunk<PrimaryInfo, void, RejectWithMessage>(
  'employer-setup/primary-info-form-data',
  async (_, { rejectWithValue }) => {
    try {
      return await employersAPI.getPrimaryInfo();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const setupEmployerName = createAsyncThunk<void, PrimaryInfo, RejectWithError>(
  'employer-setup/setup-primary-info',
  async (data, { rejectWithValue }) => {
    try {
      return await employersAPI.setupPrimaryInfo(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getDetails = createAsyncThunk<EmployerDetailsResponse, void, RejectWithMessage>(
  'employer-setup/get-details',
  async (_, { rejectWithValue }) => {
    try {
      return await employersAPI.getDetailsFormData();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const setupDetails = createAsyncThunk<void, FormData, RejectWithError>(
  'employer-setup/setup-details',
  async (data, { rejectWithValue }) => {
    try {
      return await employersAPI.setupDetails(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getFocusAreas = createAsyncThunk<EmployerFocusAreaFormData, void, RejectWithMessage>(
  'employer-setup/get-focus-areas',
  async (_, { rejectWithValue }) => {
    try {
      return await employersAPI.getFocusAreas();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const setupFocusAreas = createAsyncThunk<void, EmployerFocusAreaForm, RejectWithError>(
  'employer-setup/setup-focus-areas',
  async (data, { rejectWithValue }) => {
    try {
      return await employersAPI.setupFocusAreas(data);
    } catch (error) {
      if ((axios.isAxiosError(error) && error.response) || error instanceof Error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getEmployerDetails = createAsyncThunk<EmployerSettingsDetailsFormResponse, void, RejectWithMessage>(
  'employer-setup/get-employer-details',
  async (_, { rejectWithValue }) => {
    try {
      return await employersAPI.getEmployerDetails();
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

const initialState: EmployerSetupState = {
  setupStep: null,
  status: 'idle',
  error: null,
  data: {
    primaryInfo: null,
    details: null,
    selectedEmployer: null,
    organizationTypesSelectList: null,
  },
};

const employerSetupSlice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
    setSetupStep: (state, action: PayloadAction<AccountSetupStep>) => {
      state.setupStep = action.payload.step;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployerName.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEmployerName.fulfilled, (state, action) => {
      state.data.primaryInfo = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getEmployerName.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(setupEmployerName.fulfilled, (state) => {
      state.setupStep = AccountSetupSteps.DETAILS;
    });
    builder.addCase(getDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getDetails.fulfilled, (state, action) => {
      state.data.details = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(getDetails.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(setupDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setupDetails.fulfilled, (state) => {
      state.setupStep = AccountSetupSteps.PREFERENCES;
      state.status = 'fulfilled';
    });
    builder.addCase(setupDetails.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(getFocusAreas.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFocusAreas.fulfilled, (state, action) => {
      state.data.organizationTypesSelectList = action.payload.organizationTypesSelectList;
      state.status = 'fulfilled';
    });
    builder.addCase(getFocusAreas.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(setupFocusAreas.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setupFocusAreas.fulfilled, (state) => {
      state.setupStep = AccountSetupSteps.SETUP_SUCCESS;
      state.status = 'fulfilled';
    });
    builder.addCase(setupFocusAreas.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(getEmployerDetails.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getEmployerDetails.fulfilled, (state, action: PayloadAction<EmployerSettingsDetailsFormResponse>) => {
      state.status = 'fulfilled';
      state.data.selectedEmployer = action.payload;
      state.error = null;
    });
    builder.addCase(getEmployerDetails.rejected, (state) => {
      state.status = 'rejected';
    });
  }
});

export const employerSetupStep = (state: RootState): number | null => state.employerSetup.setupStep;
export const { setSetupStep } = employerSetupSlice.actions;
export const employerSetupReducer = employerSetupSlice.reducer;